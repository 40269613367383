import { getWatches } from "../nodeapi";

const GetWatchesService = async (address: string) => {
  try {
    const response = await getWatches(address);
    return response.data.watches;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export default GetWatchesService;
