import http from "./httpService";
import { encrypt } from "./crpyto";

const apiEndpoint = process.env.REACT_APP_CBWC_WEB_API_URL;

const options = {
  clearCacheEntry: false,
  headers: {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*"
  }
};

export async function getAllActiveProducts(req, clearCacheEntry = false) {
  return http.get(apiEndpoint + "/products.json?status=active", options);
}

export function completeCheckout(req, clearCacheEntry = false) {
  const _req = {
    checkoutId: encrypt(req.checkoutId),
    walletAddress: encrypt(req.walletAddress)
  };
  return http.post(apiEndpoint + "/shopify/completeCheckout", _req, options);
}

export function checkoutEmailUpdateV2(req, clearCacheEntry = false) {
  const _req = {
    checkoutId: encrypt(req.checkoutId),
    email: encrypt(req.email)
  };
  return http.post(
    apiEndpoint + "/shopify/checkoutEmailUpdateV2",
    _req,
    options
  );
}

export function createOrder(req, clearCacheEntry = false) {
  const _req = {
    checkoutId: encrypt(req)
  };
  return http.post(apiEndpoint + "/shopify/createOrder", _req, options);
}

export function getUserWalletDetails(_wd, clearCacheEntry = false) {
  const optionsWithAuth = {
    clearCacheEntry: true,
    headers: {
      "content-type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  };

  const encodedparam = encodeURIComponent(encrypt(_wd));
  return http.get(
    apiEndpoint + "/nft/getwalletdetailsnew/" + encodedparam,
    optionsWithAuth
  );
}

export function getDashboardNFTs(_wd, clearCacheEntry = false) {
  const optionsWithAuth = {
    clearCacheEntry: true,
    headers: {
      "content-type": "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  };
  const encodedparam = encodeURIComponent(encrypt(_wd));
  const _req = {
    walletaddress: encrypt(_wd)
  };
  return http.get(
    apiEndpoint + "/nft/getDashboardNFTsnew/" + encodedparam,
    optionsWithAuth
  );
}

export function getNFTMetaData(id) {
  return http.get(apiEndpoint + "/nft/getMetadata/" + id, options);
}

export function getNftStats() {
  return http.get(apiEndpoint + "/nft/nftStats", options);
}

export async function getClaimablePieces(address) {
  return http.get(
    apiEndpoint + "/pieces/claimable?address=" + address,
    options
  );
}

export async function getWatches(address) {
  return http.get(apiEndpoint + "/watches?address=" + address, options);
}

export async function getPieces(address) {
  return http.get(apiEndpoint + "/pieces?address=" + address, options);
}

export async function getCombinations() {
  return http.get(apiEndpoint + "/watches/combinations", options);
}

export async function forgeWatch(tokenIds) {
  const _req = {
    tokenIds
  };
  return http.post(apiEndpoint + "/watches", _req, options);
}

export async function getOrderCount(address) {
  return await http.get(apiEndpoint + "/shop/getOrderCount/" + address, options);
}

export function addOrderCount(address, count, clearCacheEntry = false) {
  const _req = {
    walletaddress: address,
    count:count
  };
  return http.post(apiEndpoint + "/shop/countOrder", _req, options);
}