import { watchContract } from "../getContract";
import { forgeTxSubmitted } from "../../hooks/swal2";

const GAS_BUFFER = 25000;

export const forge = async (data, account) => {
  try {
    const contract = watchContract();
    const gasEstimate = await contract?.methods
      .forge(data.tokenIds, data.nonce, data.deadline, data.signature)
      .estimateGas({
        from: account
      });
    const receipt = await contract?.methods
      .forge(data.tokenIds, data.nonce, data.deadline, data.signature)
      .send({
        from: account,
        gasLimit: gasEstimate + GAS_BUFFER
      })
      .on("transactionHash", function (hash) {
        forgeTxSubmitted(hash);
      });
    return receipt.status;
  } catch (error) {
    console.log(error);
  }
  return false;

};
