import { Spinner } from 'react-bootstrap';

interface PageLoaderProps{
  enable?:boolean;
}

const PageLoader = (props: PageLoaderProps) => {
  const { enable = false } = props;

  return (
    enable && (
      <div className="pageLoaderRoot">
          <Spinner animation="border" variant="primary" />
      </div>
    )
  );
};

export default PageLoader;
