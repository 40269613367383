import React, { useState } from 'react';
import CdnImageNft from '../../../../common/CdnImageNft';
import NftDetailsModel from './NftDetailsModel';

interface NftCardSimpleProps {
  id: string;
  tier: string;
  openStats:any;
}

const NftCardSimple = React.memo((props: NftCardSimpleProps) => {
  const { id = '', tier = '' , openStats} = props;
  const [modelDetail, setModelDetail] = useState(false);


  const onViewDetails = () => {
    setModelDetail(true);
  };

  return (
    <div
      className="col text-center"
      style={{ width: '100%', display: 'inline-block' }}
    >
      <div className="">
        <CdnImageNft id={id} className="img-fluid" />
        <div className="nftDetails primaryBG">
          <h3>CBWC #{id}</h3>
          <h4 className="text-capitalize whiteColor opacity-50 mb-3">
            Tier {tier}
          </h4>
          <div onClick={onViewDetails} className="w-100 btnSecondary">
            VIEW DETAILS
          </div>
          {modelDetail && (
            <NftDetailsModel
              id={id}
              tier={tier}
              detailModelView={modelDetail}
              openStats={openStats}
              setDetailModelView={(val) => setModelDetail(val)}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default NftCardSimple;
