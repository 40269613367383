import {
  DISCORD_LINK,
  OPENSEA_LINK,
  TWITTER_LINK,
} from '../../../../constants/constants';
import OpenSea from '../../../../assets/images/OpenSea.svg';
import discord from '../../../../assets/images/discord.svg';
import twitter from '../../../../assets/images/twitter.svg';
import insta from '../../../../assets/images/insta.svg';

const Footer = (props: any) => {
  const { isBottom = false } = props;

  return (
    <section className={isBottom ? 'footer bottomFixed' : 'footer'}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-3 text-end order-lg-last">
            <div className="text-end socialIcons justify-content-center">
              <a
                href={OPENSEA_LINK}
                target="_blank"
                rel="noreferrer"
                title="BUY ON OPENSEA"
              >
                <img src={OpenSea} alt="OpenSea" />
              </a>
              <a
                href={DISCORD_LINK}
                target="_blank"
                rel="noreferrer"
                title="JOIN DISCORD"
              >
                <img src={discord} alt="Discord" />
              </a>
              <a
                href={TWITTER_LINK}
                target="_blank"
                rel="noreferrer"
                title="FOLLOW ON TWITTER"
              >
                <img src={twitter} alt="Twitter" />
              </a>
              <a
                href="https://www.instagram.com/arkouda_nft/"
                target="_blank"
                rel="noreferrer"
                title="FOLLOW ON INSTA"
              >
                <img src={insta} alt="Instagram" />
              </a>
            </div>
          </div>
          <div className="col-12 col-lg-6 text-center order-lg-2">
            © Arkouda 2022. All rights reserved.
          </div>
          <div className="col-12 col-lg-3 text-center text-lg-start">
            <ul>
              <li>
                <a
                  href="https://assets.cbwc.io/cbwc-privacy-policy.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
