import LandingLayout from "../LandingLayout/LandingLayout";
import OfferImagebanner from "../../../member-portal/components/OfferImagebanner/OfferImagebanner";
import { getNewsList } from "./getNewsList";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as RoutePath from "../../../../router/config";

const NewsLanding = () => {
  // const [newsData, setNewsData]: any = useState({});
  const navigate = useNavigate();
  // useEffect(() => {
  //   getNewsList().then((response) => {
  //     console.log("response: ", response);
  //     setNewsData(response);
  //   });
  // }, []);

  // var newsPosts = newsData.newsPosts ? newsData.newsPosts : {};
  // var nodesLength = newsPosts.nodes ? newsPosts.nodes.length : 0;

  // let heroTitle = "Welcome to";
  // let heroSubtitle = "CBWC News";
  // if (
  //   newsData.listingBy &&
  //   newsData.listingBy.listingPageDetails &&
  //   newsData.listingBy.listingPageDetails.title
  // ) {
  //   heroTitle = newsData.listingBy.listingPageDetails.title;
  // }
  // if (
  //   newsData.listingBy &&
  //   newsData.listingBy.listingPageDetails &&
  //   newsData.listingBy.listingPageDetails.subtitle
  // ) {
  //   heroSubtitle = newsData.listingBy.listingPageDetails.subtitle;
  // }

  // let metaTitle = "CryptoBear Watch Club News - CBWC";
  // if (
  //   newsData.listingBy &&
  //   newsData.listingBy.seoMetaTags &&
  //   newsData.listingBy.seoMetaTags.metaTitle
  // ) {
  //   metaTitle = newsData.listingBy.seoMetaTags.metaTitle;
  // }
  // let metaDescription =
  //   "Find the latest news and updates of CBWC. The most exciting project to come out in recent years. A collection of 3,010 exclusive NFT collectibles";
  // if (
  //   newsData.listingBy &&
  //   newsData.listingBy.seoMetaTags &&
  //   newsData.listingBy.seoMetaTags.metaDescription
  // ) {
  //   metaDescription = newsData.listingBy.seoMetaTags.metaDescription;
  // }

  const onReadMore = (slug: string) => {
    console.log("slug: ", slug);
    navigate(RoutePath.NEWS_DETAILS, { state: { slug } });
  };

  return (
    <div className="adminSection innerPage">
      <LandingLayout>
        <div className="innerBodySection news">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="mb-0">CBWC</h1>
                <h1>
                  <span>NEWS AND BLOGS</span>
                </h1>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row mt-3 mt-lg-4">
              <div className="col-12">
                <div className="headingOpt">
                  <h3 className="float-start">LATEST NEWS AND BLOGS</h3>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
            <OfferImagebanner />

            {/* <div className="row mt-3 mt-lg-5">
              <div className="col-12">
                <div className="headingOpt">
                  <h3 className="float-start">RECENT NEWS</h3>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div> */}
            {/* <div className="row g-3">
              {newsPosts.nodes &&
                newsPosts.nodes.map((eachNews) => {
                  const { image = {}, description } = eachNews?.newsDetails;
                  return (
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="secondaryBG">
                        <div className="text-decoration-none">
                          <div>
                            <img
                              src={image.mediaItemUrl}
                              className="img-fluid"
                              alt={image.altText}
                            />
                          </div>
                          <div className="offersBlock newsBlock">
                            <h3>{eachNews.title}</h3>
                            <h4>{eachNews.date}</h4>
                            <div
                              dangerouslySetInnerHTML={{ __html: description }}
                            />
                            <div className="mt-3">
                              <div
                                onClick={() => onReadMore(eachNews.slug)}
                                className="w-100 btnPrimary"
                              >
                                READ MORE
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              <div className="clearfix"></div>
              <div className="loadMore mt-3 mt-lg-5 text-center">
                <a href="#">Load more</a>
              </div>
            </div> */}
          </div>
        </div>
      </LandingLayout>
    </div>
  );
};

export default NewsLanding;
