interface HeaderTextPropsType {
  title: string;
  subtitle: string;
}

const HeaderText = (props: HeaderTextPropsType) => {
  const { title = '', subtitle = '' } = props;
  return (
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-12 titleBox">
          <h1>{title}</h1>
          <h2>
            <span>{subtitle}</span>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default HeaderText;
