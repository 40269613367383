import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import backIcon from '../../../../assets/images/navigationIcons/home-icon-white.svg';
import { useCustomWeb3React } from '../../../../hooks/useCustomWeb3React';
import * as Routepath from '../../../../router/config';
import connectedImg from '../../../../assets/images/online-round-icon.svg';
import bearimg from '../../../../assets/images/bear-icon-for-user.svg';
import { DispatchContext } from '../../../../context/context';
import * as appContextAction from '../../../../context/actions/appContextActions';

const ProfileMenu = (props: any) => {
  const { deactivate, account } = useCustomWeb3React();
  const appContextDispatch = useContext(DispatchContext);

  const navigate = useNavigate();

  // const onDisconnect = async () => {
  //   await deactivate();
  //   localStorage.removeItem('account');
  //   navigate(Routepath.ROOT);
  // };

  const onDisconnect = async () => {
    appContextDispatch(appContextAction.piecesResponse({}));
    appContextDispatch(appContextAction.walletDetailResponse({}));
    appContextDispatch(appContextAction.offerResponse({}));
    await deactivate();
    window.location.reload();
    navigate(Routepath.ROOT);
  };

  const formattedAccount = account
    ? account.substring(0, 4) +
      '....' +
      account.substring(account.length - 3, account.length)
    : '0X...';

  return (
    <li className="nav-item dropdown">
      <g
        className="nav-link"
        id="userDetails"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <div className="userDetails float-start">
          <h6>{formattedAccount}</h6>
          <p>
            <img src={connectedImg} alt="Connected" className="availUser" />
            CONNECTED
          </p>
        </div>
        <img src={bearimg} alt="User" />
        <span className="float-end"></span>
      </g>
      <ul className="dropdown-menu" aria-labelledby="userDetails">
        <li style={{ cursor: 'pointer' }}>
          <a onClick={onDisconnect} className="dropdown-item">
            DISCONNECT WALLET
          </a>
        </li>
      </ul>
    </li>
  );
};

export default ProfileMenu;
