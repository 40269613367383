import { ADD_NOTIFICATIONS, CART_ITEMS, OFFER_RESPONSE, PEICES_RESPONSE, SIZE_SELECTED, WALLET_DETAILS_RESPONSE } from '../actions/appContextActions';

const initialState: any = {
  notifications:  [
    {
      id: '12',
      title: 'This is testing',
      subtitle: 'asdasd asd asd ad sad as dsa ',
    },
    {
      id: '13',
      title: 'This is testing',
      subtitle: 'asdasd asd asd ad sad as dsa ',
    },
  ],
  walletResponse:{},
  cartItems:[],
  sizeSlected:'Small'
};

export const AppContextReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case WALLET_DETAILS_RESPONSE:
      return {
        ...state,
        walletResponse: action.payload,
      };
    case PEICES_RESPONSE:
      return {
        ...state,
        pieceResponse: action.payload,
      };
    case OFFER_RESPONSE:
      return {
        ...state,
        offerResponse: action.payload,
      };
    case SIZE_SELECTED:
      return {
        ...state,
        sizeSlected: action.payload,
      };
    case CART_ITEMS:
      return {
        ...state,
        cartItems: action.payload,
      };
    default:
      return state;
  }
};
