import Web3 from "web3";

export const conciseAddress = (address:any) => {
  if (Web3.utils.isAddress(address)) {
    return `${address.slice(0, 6)}...${address.slice(
      address.length - 3,
      address.length
    )}`;
  }
  return "-";
};

export const toEther = (num:string) => {
  try {
    return Web3.utils.fromWei(num, "ether");
  } catch {
    return "0";
  }
};
