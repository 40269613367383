import { ApolloClient, InMemoryCache } from "@apollo/client";
require('dotenv').config();

const defaultOptions:Object = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
};
const client = new ApolloClient({
    uri: "https://admin.cbwc.io/graphql",//process.env.NEXT_PUBLIC_GQ,
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
});

export default client;