import { nftContract, stakingContract } from "../getContract"
import {
  transactionRejected,
  transactionSuccess,
  transactionFailed,
} from "../../hooks/swal2"
import { CONTRACT_ADDRESSES } from "../../constants/constants"
import { nftContractMainnet } from "../getContractMainNet"
import Web3 from "web3"

export const allowance = async account => {
  try {
    const contract = nftContract()
    return await contract?.methods
      .isApprovedForAll(account, CONTRACT_ADDRESSES.staking)
      .call()
  } catch (e) {
    console.log("isApprovedForAll", e)
    return false
  }
}

export const cbwcBalance = async account => {
  try {
    const contract = nftContract()
    const resCount = await contract?.methods.balanceOf(account).call()
    return(resCount)
  } catch (e) {
    console.log("cbwcCount", e)
    return false
  }
}



export const cbwcTotalStacked = async account => {
  try {
    const contract = nftContractMainnet()
    const resCount = await contract?.methods.balanceOf(account).call()
    return(resCount)

  } catch (e) {
    console.log("cbwcCount", e)
    return false
  }
}


export const approve = async (account, callBack = e => {}) => {
  try {
    let txHash

    const contract = nftContract()
    await contract?.methods
      .setApprovalForAll(CONTRACT_ADDRESSES.staking, true)
      .send({
        from: account,
      })
      .on("transactionHash", function (hash) {
        txHash = hash
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber == 1) {
          receipt.status == true ? transactionSuccess() : transactionFailed()
        }
      })
      .on("error", function (error, receipt) {
        if (error.code === 4001) {
          transactionRejected()
        } else {
          transactionFailed()
        }
      })
    callBack(undefined)
  } catch (e) {
    console.log("setApprovalForAll", e)
    callBack(e)
  }
}


export const TransferTokens = async (_amount, account, callBack = e => {}) => {
  try {
    let txHash
    let web3 = new Web3(window?.web3?.currentProvider)
    
    let toAddress = "0xe0044241b3036895A75C157002d51d949D6860B8"

    let decimals = web3.utils.toBN(18)
    let amount = web3.utils.toBN(parseFloat(_amount))
    let value = amount.mul(web3.utils.toBN(10).pow(decimals))
    
    const contract = stakingContract()

    await contract.methods.transfer(toAddress, value).send({from: account}).on("transactionHash", function (hash) {
        txHash = hash
      }).on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber == 1) {
          //receipt.status == true ? transactionSuccess() : transactionFailed()
        }
      })
      .on("error", function (error, receipt) {
        if (error.code === 4001) {
          transactionRejected()
        } else {
          transactionFailed()
        }
      })

    callBack(undefined)
    return txHash
  } catch (e) {
    console.log("mint", e)
    callBack(e)
  }
}

// // ========================================== Mint

// export const mint = async (count, amount, account, callBack = e => {}) => {
//   try {
//     const contract = nftContract()
//     await contract?.methods
//       .mint(count)
//       .send({
//         from: account,
//         value: amount,
//       })
//       .on("transactionHash", function (hash) {
//         txHash = hash
//       })
//       .on("confirmation", function (confirmationNumber, receipt) {
//         if (confirmationNumber == 1) {
//           receipt.status == true ? transactionSuccess() : transactionFailed()
//         }
//       })
//       .on("error", function (error, receipt) {
//         if (error.code === 4001) {
//           transactionRejected()
//         } else {
//           transactionFailed()
//         }
//       })
//     callBack(undefined)
//   } catch (e) {
//     console.log("mint", e)
//     callBack(e)
//   }
// }


// // vip
// export const privateSaleMint = async (count, account, callBack = (e) => {}) => {
//   try {
//     const contract = nftContract();
//     await contract?.methods.privateSaleMint(count).send({
//       from: account,
//     });
//     callBack(undefined);
//   } catch (e) {
//     console.log("privateSaleMint", e);
//     callBack(e);
//   }
// };

// export const presaleMint = async (
//   _proof,
//   _allowedCount,
//   count,
//   amount,
//   account,
//   callBack = (e) => {}
// ) => {
//   try {
//     let txHash;
//     setRecoil(mintAtom, true);
//     setRecoil(openMintAtom, false);
//     const contract = nftContract();
//     await contract?.methods
//       .presaleMint(_proof, _allowedCount, count)
//       .send({
//         from: account,
//         value: amount,
//       })
//       .on("transactionHash", function (hash) {
//         txHash = hash;
//       })
//       .on("confirmation", function (confirmationNumber, receipt) {
//         if (confirmationNumber == 1) {
//           receipt.status == true ? mintSuccessful() : mintFailed();
//           setRecoil(mintAtom, false);
//         }
//       })
//       .on("error", function (error, receipt) {
//         if (error.code === 4001) {
//           transactionRejected();
//         } else {
//           mintFailed();
//         }
//         setRecoil(mintAtom, false);
//       });
//     callBack(undefined);
//   } catch (e) {
//     console.log("presaleMint", e);
//     callBack(e);
//   }
// };

// // ==================================== Price

// export const currentPrice = async () => {
//   try {
//     const contract = nftContract();
//     return await contract?.methods.dutchAuction().call();
//   } catch (e) {
//     console.log("currentPrice", e);
//     return "0";
//   }
// };

// export const presalePrice = async () => {
//   try {
//     const contract = nftContract();
//     return await contract?.methods.PRESALE_PRICE().call();
//   } catch (e) {
//     console.log("PRESALE_PRICE", e);
//     return "0";
//   }
// };

// // ==================================== For Dynamic Max Count
// export const whitelistMaxCount = async (account) => {
//   try {
//     const contract = nftContract();
//     return await contract?.methods.preSaleMintCount(account).call();
//   } catch (e) {
//     console.log("Private sale mint count", e);
//     return "0";
//   }
// };
