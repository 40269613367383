import { Spinner } from 'react-bootstrap';

interface Loaderprops{
  enable:boolean
}

const Loader = (props: Loaderprops) => {
  const { enable = false } = props;

  return (
    enable && (
      <div className="loaderRoot">
          <Spinner animation="border" variant="primary" />
      </div>
    )
  );
};

export default Loader;
