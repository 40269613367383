import Web3 from "web3"
import {
  CONTRACT_ADDRESSES,
  CHAIN_ID,
  INFURA_KEY,
} from "../constants/constants"

import NFTContractABI from "./abis/NFTContract.json"
import StakingContractABI from "./abis/Staking.json"
import PiecesContractABI from "./abis/Pieces.json";
import WatchContractABI from "./abis/Watch.json";

let web3
let web3Infura
const infuraUrl =
  CHAIN_ID === 1
    ? `https://mainnet.infura.io/v3/${INFURA_KEY}`
    // : `https://rinkeby.infura.io/v3/${INFURA_KEY}`
    : "https://eth-rinkeby.alchemyapi.io/v2/enIxIv3YKFLNw89cfEvImz34dcQXqNKH"

try {
  if (typeof window !== "undefined")
    web3 = new Web3(window?.web3?.currentProvider)
  web3Infura = new Web3(infuraUrl)
} catch (e) {
  console.log("Connect Web3", e)
}

export const setWeb3Provider = provider => {
  web3 = new Web3(provider)
}

export const nftContract = () => {
  let contract
  try {
    if (
      (typeof window !== "undefined" && window?.web3?.currentProvider) ||
      web3
    ) {
      contract = new web3.eth.Contract(
        NFTContractABI,
        CONTRACT_ADDRESSES.crypto_bear
      )
    } else {
      contract = new web3Infura.eth.Contract(
        NFTContractABI,
        CONTRACT_ADDRESSES.crypto_bear
      )
    }
    return contract
  } catch (e) {
    console.log("contract init", e)
  }
}

export const stakingContract = () => {
  let contract
  try {
    if (
      (typeof window !== "undefined" && window?.web3?.currentProvider) ||
      web3
    ) {
      contract = new web3.eth.Contract(
        StakingContractABI,
        CONTRACT_ADDRESSES.staking
      )
    } else {
      contract = new web3Infura.eth.Contract(
        StakingContractABI,
        CONTRACT_ADDRESSES.staking
      )
    }
    return contract
  } catch (e) {
    console.log("contract init", e)
  }
}

export const piecesContract = () => {
  let contract;
  try {
    if (
      (typeof window !== "undefined" && window?.web3?.currentProvider) ||
      web3
    ) {
      contract = new web3.eth.Contract(PiecesContractABI, CONTRACT_ADDRESSES.pieces);
    } else {
      contract = new web3Infura.eth.Contract(
        PiecesContractABI,
        CONTRACT_ADDRESSES.pieces
      );
    }
    return contract;
  } catch (e) {
    console.log("contract init", e);
  }
};

export const watchContract = () => {
  let contract;
  try {
    if (
      (typeof window !== "undefined" && window?.web3?.currentProvider) ||
      web3
    ) {
      contract = new web3.eth.Contract(
        WatchContractABI,
        CONTRACT_ADDRESSES.watch
      );
    } else {
      contract = new web3Infura.eth.Contract(
        WatchContractABI,
        CONTRACT_ADDRESSES.watch
      );
    }
    return contract;
  } catch (e) {
    console.log("contract init", e);
  }
};
