import Aboutus from "./component/Aboutus";
import DashboardSection from "./component/DashboardSection";
import Faq from "./component/Faq";
import FirstDropSection from "./component/FirstDrop";
import Footer from "./component/Footer";
import HeadSection from "./component/HeadSection/index";
import LandingLayout from "./component/LandingLayout/LandingLayout";
import NavbarSection from "./component/NavbarSection";
import NewDrop from "./component/NewDrop";
import Perks from "./component/Perks";
import Roadmap from "./component/Roadmap";
import SpecialNft from "./component/SpecialNft";
import TeamSection from "./component/TeamSection";

const LandingSite = () => {
  return (
    <LandingLayout>
      <HeadSection />
      <FirstDropSection />
      <NewDrop />
      <SpecialNft />
      {/* <Perks /> */}
      <DashboardSection />
      <Roadmap />
      <Aboutus />
      <TeamSection />
      <Faq />
    </LandingLayout>
  );
};

export default LandingSite;
