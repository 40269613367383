import { useEffect, useState } from "react";
import { useCustomWeb3React } from "../../../hooks/useCustomWeb3React";
import ContainerForgeComponent from "../components/Forge/ContainerForge/containerForgeComponent";
import HeaderText from "../components/HeaderText/HeaderText";

export default function Forge() {
  const [account, setAccount] = useState("");
  const { account: accountWeb3 } = useCustomWeb3React();

  useEffect(() => {
    if (accountWeb3 && accountWeb3 !== undefined) {
      localStorage.setItem("account", accountWeb3);
    }

    const local_acc: string = localStorage.getItem("account");
    if (local_acc && local_acc !== undefined) {
      setAccount(local_acc);
    }
  }, [setAccount, accountWeb3]);

  return (
    <div className="stakingSection mt-4 mb-5">
      <HeaderText title="WATCH GENERATOR" subtitle={`COLLECT & FORGE INTO WATCH NFT`} />
      {account.length > 0 ? (<div className=" mt-3 mt-lg-4">
        <ContainerForgeComponent account={account} />{" "}
      </div>) : null}
    </div>
  );
}


