import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import backIcon from '../../../../assets/images/navigationIcons/home-icon-white.svg';
import { useCustomWeb3React } from '../../../../hooks/useCustomWeb3React';
import * as Routepath from '../../../../router/config';
import NotificationsMobile from './NotificationsMobile';
import NotificationsWeb from './NotificationsWeb';
import logo from "../../../../assets/images/cbwc-logo.png"
import { useWeb3React } from '@web3-react/core';
import { DispatchContext } from '../../../../context/context';
import * as appContextAction from "../../../../context/actions/appContextActions";
import _ from "lodash"

interface NavbarMemberProps {
  onMenuClick: any
}

const NavbarMember = (props: NavbarMemberProps) => {
  const { onMenuClick = () => { } } = props;
  const { account } = useCustomWeb3React();
  const { deactivate } = useWeb3React();
  const navigate = useNavigate();
  const [navClick, setNavClick] = useState(false)
  const appContextDispatch = useContext(DispatchContext);

  const onBackHome = () => {
    navigate(Routepath.ROOT);
  };

  const onDisconnect = async () => {
    appContextDispatch(appContextAction.piecesResponse({}));
    appContextDispatch(appContextAction.walletDetailResponse({}));
    appContextDispatch(appContextAction.offerResponse({}));
    await deactivate();
    window.location.reload();
    navigate(Routepath.ROOT);
  };

  const formattedAccount = account ?
    account.substring(0, 4) +
    '....' +
    account.substring(account.length - 3, account.length) : "0X...";

  const onMenuClickMob = () => {
    console.log("web clicked")
    onMenuClick()
  };

  const onMenuClickWeb = () => {
    setNavClick(!navClick)
  };





  const classnav = navClick
    ? 'navbar-collapse offcanvas-collapse open'
    : 'navbar-collapse offcanvas-collapse';

  return (
    <nav className="navbar navbar-expand-lg" aria-label="Main navigation">
      <div className="container">
        <a className="navbar-brand" href="index-updated.html">
          <img
            className="light-mode-item navbar-brand-item"
            src={logo}
            alt="CBWC"
          />
        </a>

        <div className="d-none d-xl-block leftNavigationHandler ms-5">
          <button
            className="navbar-toggler p-0 border-0"
            type="button"
            onClick={onMenuClickMob}
            id="leftNavHandler"
            aria-label="Toggle navigation"
            data-toggle="collapse"
          >
            <span className="navbar-toggler-icon2"></span>
          </button>
        </div>

        <div className="d-none d-xl-block ms-5">
          <div className="backHome">
            <div className="backinnerdiv" onClick={onBackHome}>
              <img src={backIcon} alt="HOME" />
              BACK TO Arkouda HOME
            </div>
          </div>
        </div>

        <div
          className={classnav}
          id="navbarsExampleDefault"
        >
          <NotificationsWeb />

          <NotificationsMobile
            onDisconnect={onDisconnect}
            formattedAccount={formattedAccount}
          />

          <ul className="navbar-nav navbar-nav-scroll me-auto"></ul>
        </div>

        <button
          className="navbar-toggler p-0 border-0"
          type="button"
          id="navbarSideCollapse"
          onClick={onMenuClickWeb}
          aria-label="Toggle navigation"
          data-toggle="collapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
  );
};

export default NavbarMember;
