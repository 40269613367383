import React, { useState } from 'react';
import notification from '../../../../assets/images/notification-icon-white-bg.svg';
import online from '../../../../assets/images/online-round-icon.svg';
import bear from '../../../../assets/images/bear-icon-for-user.svg';
import NotificationsList from '../Notifications/NotificationsList';
import { StateContext } from "../../../../context/context";

interface HeaderTextPropsType {
  title: string;
  subtitle: string;
}

const NotificationsMobile = (props: any) => {
  const [activeTab, setActiveTab] = useState('A');
  const { notifications = [] }: any = React.useContext(StateContext);

  const { formattedAccount, onDisconnect } = props;

  return (
    <div className="d-block d-lg-none mt-5">
      <nav>
        <div className="nav nav-tabs navigationTab" id="nav-tab" role="tablist">
          <button
            className={activeTab === 'A' ? 'nav-link active' : 'nav-link'}
            id="nav-home-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-home"
            onClick={() => setActiveTab('A')}
            type="button"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
          >
            <div className="xsNotificationBar">
              <div>
                <img
                  src={notification}
                  alt="Notification"
                  className="notifyIcon"
                />
               {notifications.length>0 &&<span className="notificationAlert"></span>}
              </div>{' '}
              NOTIFICATION
            </div>
          </button>
          <button
            className={activeTab === 'B' ? 'nav-link active' : 'nav-link'}
            onClick={() => setActiveTab('B')}
            id="nav-profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-profile"
            type="button"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
          >
            <g
              className="nav-link"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="userDetails float-start">
                <h6>{formattedAccount}</h6>
                <p>
                  <img src={online} alt="Connected" className="availUser" />
                  CONNECTED
                </p>
              </div>
              <img src={bear} alt="User" />
              <span className="float-end"></span>
            </g>
          </button>
        </div>
      </nav>
      <div className="tab-content" id="nav-tabContent">
        <div
          className={
            activeTab === 'A' ? 'tab-pane fade show active' : 'tab-pane fade'
          }
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          <NotificationsList/>
        </div>
        <div
          className={
            activeTab === 'B' ? 'tab-pane fade show active' : 'tab-pane fade'
          }
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
        >
          <ul className="userMenu" aria-labelledby="userDetails">
            {/* <li>
            <a href="#">ORDERS</a>
          </li>
          <li>
            <a href="#">TRANSACTIONS</a>
          </li> */}
            <li style={{ cursor: 'pointer' }}>
              <a onClick={onDisconnect}>DISCONNECT WALLET</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NotificationsMobile;
