import { useState, useEffect, useContext } from "react";
import GetPiecesService from "../../../../../services/forge/getPiecesService";
import MyPiecesComponent from "../MyPieces/myPiecesComponent";
import MyWatchesComponent from "../MyWatches/myWatchesComponent";
import WatchForgeComponent from "../WatchForge/watchForgeComponent";
import CombinationService from "../../../../../services/forge/combinationService";
import GetWatchesService from "../../../../../services/forge/getWatchesService";
import "../../../../../assets/css/watch-generator.css";
import PageLoader from "../../../../../common/loader/PageLoader";
import { DispatchContext, StateContext } from '../../../../../context/context';
import * as appContextAction from "../../../../../context/actions/appContextActions";
import _ from "lodash"

interface ContainerForgeProps {
  account: string;
}

const ContainerForgeComponent = (props: ContainerForgeProps) => {
  const [combinations, setCombinations] = useState([]);
  const [piecesToForge, setPiecesToForge] = useState([]);
  const [myPieces, setMyPieces] = useState([]);
  const [myWatches, setMyWatches] = useState([]);
  const [updateAssets, setUpdateAssets] = useState(true);
  const [loadingPiece, setLoadingPiece] = useState(false);
  const [loadForging, setLoadForging] = useState(false);

  const { pieceResponse }: any = useContext(StateContext);
  const appContextDispatch = useContext(DispatchContext);

  async function getCombinations(combination) {
    const combinations = await CombinationService(combination);
    setCombinations(combinations);
  }

  async function valid() {
    let baseDial = "";
    let baseStrap = "";
    let baseCase = "";
    piecesToForge.forEach(function (piece) {
      if (piece.type === "Case") {
        baseCase = piece.code;
      }
      if (piece.type === "Dial") {
        baseDial = piece.code;
      }
      if (piece.type === "Strap") {
        baseStrap = piece.code;
      }
    });
    const combination = {
      dial: baseDial,
      strap: baseStrap,
      case: baseCase,
    };
    await getCombinations(combination);
  }

  async function handlePieceSelected(tokenId) {
    let pieceToForgeIdx;
    for (let i = 0; i < myPieces.length; i++) {
      if (myPieces[i].tokenId === tokenId) {
        pieceToForgeIdx = i;
        break;
      }
    }
    const pieceToForge = myPieces.splice(pieceToForgeIdx, 1);
    if (Array.isArray(pieceToForge) && pieceToForge.length > 0) {
      piecesToForge.push(pieceToForge[0]);
    }
    await valid();
  }

  function handleNewWatchForge() {
    setUpdateAssets(true);
    setPiecesToForge([]);
  }

  async function handlePieceUnselected(tokenId) {
    let selectedPieceIdx;
    for (var i = 0; i < piecesToForge.length; i++) {
      if (piecesToForge[i].tokenid === tokenId) {
        selectedPieceIdx = i;
        break;
      }
    }
    const selectedPiece = piecesToForge.splice(selectedPieceIdx, 1);
    if (Array.isArray(selectedPiece) && selectedPiece.length > 0) {
      myPieces.push(selectedPiece[0]);
    }
    await valid();
  }

  useEffect(() => {
    setUpdateAssets(false);
    const getPieces = async () => {
      setLoadingPiece(true);
      const pieces = await GetPiecesService(props.account);
      appContextDispatch(appContextAction.piecesResponse(pieces));
      
      setMyPieces(pieces);
      setLoadingPiece(false);
    };

    const getWatches = async () => {
      const watches = await GetWatchesService(props.account);
      setMyWatches(watches);
    };

    if (updateAssets) {
      getPieces();
      getWatches();
    }
  }, [props.account, updateAssets]);

  return (
    <>
      <div className="container mt-3">


        <MyPiecesComponent
          myPieces={myPieces}
          handlePieceSelected={handlePieceSelected}
          piecesToForge={piecesToForge}
          combinations={combinations}
          isForging={true}
        />

        {myPieces.length > 0 || piecesToForge.length > 0 ? (
          <div className="mt-4  ">
            <WatchForgeComponent
              onLoading={(val)=>{setLoadForging(val)}}
              piecesToForge={piecesToForge}
              handlePieceUnselected={handlePieceUnselected}
              handleNewWatchForge={handleNewWatchForge}
              account={props.account}
            />
          </div>
        ) : null}
        <MyWatchesComponent myWatches={myWatches} />
        {(loadingPiece || loadForging) && (
          <PageLoader enable={true} />
        )}
      </div>
    </>
  );
};

export default ContainerForgeComponent;
