import { getPieces } from "../nodeapi";

const GetPiecesService = async (address: string) => {
  const response = await getPieces(address);
  if (response.status !== 200) {
    if (response.data.error) {
      throw new Error(response.data.message);
    } else {
      throw new Error("Unknown error");
    }
  }
  return response.data.pieces;
};

export default GetPiecesService;
