import {
  approveDialog,
  transactionFailed,
  transactionInProcess,
  transactionRejected,
  transactionSuccess,
} from "../../hooks/swal2"
import {  stakingContract } from "../getContract"
import { allowance } from "./nftContract"
// import { setRecoil } from "recoil-nexus";
// import { mintAtom, openMintAtom } from "recoil-state";
// import {
//   mintSuccessful,
//   mintFailed,
//   transactionRejected,
// } from "src/hooks/swal2";

export const balanceOf = async account => {
  try {
    const contract = stakingContract()
    const d = await contract?.methods.balanceOf(account).call()

    return d // await contract?.methods.balanceOf(account).call()
  } catch (e) {
    return "0"
  }

}

export const totalSupplyArkoda = async ()  => {
  try {
    const contract = stakingContract()
    const d = await contract?.methods.totalSupply().call()

    return d // await contract?.methods.balanceOf(account).call()
  } catch (e) {
    return "0"
  }
}

export const getTotalClaimable = async (tokenIds, account) => {
  try {
    const contract = stakingContract()
    let c = await contract?.methods.getTotalClaimable(tokenIds).call({
      from: account,
    })
    return c
  } catch (e) {
    console.log("getTotalClaimable", e)
    return "0"
  }
}

export const getTokenIdTier = async tokenId => {
  try {
    const contract = stakingContract()
    let t = await contract?.methods.tokenIdTier(tokenId).call()
    return parseInt(t) > 0 ? t : "3"
  } catch (e) {
    console.log("getTotalClaimable", e)
    return "-"
  }
}

// export const allowance = async (account) => {
//   try {
//     const contract = stakingContract();
//     return (
//       parseFloat(
//         await contract?.methods
//           .allowance(account, CONTRACT_ADDRESSES.staking)
//           .call()
//       ) > 0
//     );
//   } catch (e) {
//     console.log("allowance", e);
//     return false;
//   }
// };

// export const approve = async (account, callBack = (e) => {}) => {
//   try {
//     const contract = stakingContract();
//     await contract?.methods
//       .approve(CONTRACT_ADDRESSES.staking, MAX_UINT_256)
//       .send({
//         from: account,
//       });
//     callBack(undefined);
//   } catch (e) {
//     console.log("approve", e);
//     callBack(e);
//   }
// };

export const stakeCBWC = async (tokenIds, account, callBack = e => {}) => {
  try {
    if (!(await allowance(account))) {
      approveDialog(account)
      return
    }

    let txHash
    const contract = stakingContract()
    await contract?.methods
      .stakeCBWC(tokenIds)
      .send({
        from: account,
      })
      .on("transactionHash", function (hash) {
        txHash = hash
        transactionInProcess({
          msg: `Transaction Hash (you will be notified on success) ${hash}.`,
        })

      })
      .on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber == 1) {
          receipt.status == true
            ? transactionSuccess({
                msg: `Token id(s) ${tokenIds.join(", ")} staked successfully.`,
              })
            : transactionFailed()
        }
      })
      .on("error", function (error, receipt) {
        if (error.code === 4001) {
          transactionRejected()
        } else {
          transactionFailed()
        }
      })
    callBack(undefined)
  } catch (e) {
    console.log("stakeCBWC", e)
    callBack(e)
  }
}

export const unStakeCBWC = async (tokenIds, account, callBack = e => {}) => {
  try {
    let txHash

    const contract = stakingContract()
    await contract?.methods
      .unStakeCBWC(tokenIds)
      .send({
        from: account,
      })
      .on("transactionHash", function (hash) {
        txHash = hash
        transactionInProcess({
          msg: `Transaction Hash (you will be notified on success) ${hash}.`,
        })

      })
      .on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber == 1) {
          receipt.status == true
            ? transactionSuccess({
                msg: `Token id(s) ${tokenIds.join(
                  ", "
                )} unstaked successfully.`,
              })
            : transactionFailed()
        }
      })
      .on("error", function (error, receipt) {
        if (error.code === 4001) {
          transactionRejected()
        } else {
          transactionFailed()
        }
      })
    callBack(undefined)
  } catch (e) {
    console.log("unStakeCBWC", e)
    callBack(e)
  }
}

export const claimRewards = async (tokenIds, account, callBack = e => {}) => {
  try {
    let txHash

    const contract = stakingContract()
    await contract?.methods
      .claimRewards(tokenIds)
      .send({
        from: account,
      })
      .on("transactionHash", function (hash) {
        txHash = hash
        transactionInProcess({
          msg: `Transaction Hash (you will be notified on success) ${hash}.`,
        })
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber == 1) {
          receipt.status == true
            ? transactionSuccess({
                msg: `Rewards of Token id(s) ${tokenIds.join(
                  ", "
                )} claimed successfully.`,
              })
            : transactionFailed()
        }
      })
      .on("error", function (error, receipt) {
        if (error.code === 4001) {
          transactionRejected()
        } else {
          transactionFailed()
        }
      })
    callBack(undefined)
  } catch (e) {
    console.log("claimRewards", e)
    callBack(e)
  }
}

export const getPendingReward = async (tokenId, account) => {
  try {
    const contract = stakingContract()
    let r = await contract?.methods.getPendingReward(tokenId).call({
      from: account,
    })
    return r
  } catch (e) {
    console.log("getPendingReward", e)
    return "0"
  }
}

// export const presaleMint = async (
//   _proof,
//   _allowedCount,
//   count,
//   amount,
//   account,
//   callBack = (e) => {}
// ) => {
//   try {
//     let txHash;
//     setRecoil(mintAtom, true);
//     setRecoil(openMintAtom, false);
//     const contract = nftContract();
//     await contract?.methods
//       .presaleMint(_proof, _allowedCount, count)
//       .send({
//         from: account,
//         value: amount,
//       })
//       .on("transactionHash", function (hash) {
//         txHash = hash;
//       })
//       .on("confirmation", function (confirmationNumber, receipt) {
//         if (confirmationNumber == 1) {
//           receipt.status == true ? mintSuccessful() : mintFailed();
//           setRecoil(mintAtom, false);
//         }
//       })
//       .on("error", function (error, receipt) {
//         if (error.code === 4001) {
//           transactionRejected();
//         } else {
//           mintFailed();
//         }
//         setRecoil(mintAtom, false);
//       });
//     callBack(undefined);
//   } catch (e) {
//     console.log("presaleMint", e);
//     callBack(e);
//   }
// };
