import { OPENSEA_LINK } from '../../../../constants/constants';
import FaqSlider from './FaqSlider';

const Faq = () => {
  const Questions = [
    {
      title: 'WHEN IS THE LAUNCH?',
      description: (
        <>
          Our first drop is sold out, please visit{' '}
          <a
            rel="noreferrer"
            href={OPENSEA_LINK}
            target="_blank"
          >
            OpenSea
          </a>
          .
        </>
      ),
    },
    {
      title: 'WHAT IS THE TOTAL SUPPLY?',
      description: '3,010 unique Arkouda collectibles.',
    },

    {
      title: 'WHERE ARE THE NFTs LOCATED?',
      description:
        'Arkouda NFTs are housed exclusively on the Ethereum Blockchain.',
    },

    {
      title: 'DO I GET FULL RIGHTS TO MY NFTs?',
      description:
        'Arkouda grants you full creative and commercial rights for the pieces you own. Utilize them as you wish and make the most of your collectibles.',
    },
    {
      title: 'HOW DO I REACH YOU?',
      description: (
        <>
          Send us an email at{' '}
          <a rel="noreferrer" href="mailto:info@arkouda.io">
            info@arkouda.io
          </a>
          .
        </>
      ),
    },
  ];

  return (
    <section id="faqSection">
      <div className="container FAQSec">
        <h1 className="mb-2 text-center text-lg-start" data-aos="fade-up">
          FREQUENTLY
          <br />
          ASKED <span>QUESTION</span>
        </h1>

        <h3 className="mt-5 text-center text-lg-start" data-aos="fade-up">
          Table of content
        </h3>
        <div
          className="d-lg-flex align-items-start faqStart"
          data-aos="fade-up"
        >
          <ul
            className="nav nav-tabs d-none d-lg-block faqTabs"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className="navLink active"
                id="general-tab"
                data-bs-toggle="tab"
                data-bs-target="#general"
                type="button"
                role="tab"
                aria-controls="general"
                aria-selected="true"
              >
                General
              </button>
            </li>
          </ul>
          <select
            className="mb-3 form-control d-block d-lg-none tabDropdown"
            id="tabSelector"
            data-aos="fade-up"
          >
            <option value="0">General</option>
            {/* <!--<option value="1">NFT</option>
				<option value="2">Registration</option>
				<option value="3">Raffle</option>
				<option value="4">Mint &amp; Price</option>--> */}
          </select>
          <div
            className="tab-content faqTab mb-5"
            id="myTabContent"
            data-aos="fade-left"
          >
            <div
              className="tab-pane fade show active"
              id="general"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className="accordion" id="accordionExample">
                {Questions.map((each) => {
                  return (
                    <FaqSlider
                      title={each.title}
                      description={each.description}
                    />
                  );
                })}
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="nft"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              NFT
            </div>
            <div
              className="tab-pane fade"
              id="registration"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              Registration
            </div>
            <div
              className="tab-pane fade"
              id="raffle"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              Raffle
            </div>
            <div
              className="tab-pane fade"
              id="mint"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              Mint &amp; Price
            </div>
          </div>
        </div>
        <div className="d-block d-lg-none text-center"></div>
      </div>
    </section>
  );
};

export default Faq;
