import { getCombinations } from "../nodeapi";

interface Combination {
  dial: string;
  strap: string;
  case: string;
}

let combinations: Array<any>;

const CombinationService = async (combination: Combination) => {
  if (!combinations || combinations.length === 0) {
    const response = await getCombinations();
    if (response.status !== 200) {
      if (response.data.error) {
        throw new Error(response.data.message);
      } else {
        throw new Error("Unknown error");
      }
    }
    combinations = response.data.combinations;
  }
  const validCombinations = combinations.filter((x) => {
    const dialValid =
      x.dialCode === combination.dial || combination.dial === "";
    const strapValid =
      x.strapCode === combination.strap || combination.strap === "";
    const caseValid =
      x.caseCode === combination.case || combination.case === "";
    return dialValid && strapValid && caseValid;
  });

  return validCombinations;
};

export default CombinationService;
