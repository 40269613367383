import { useWeb3React } from '@web3-react/core';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useCustomWeb3React } from '../hooks/useCustomWeb3React';
import * as RoutePath from './config';

const ProtectedRoute: any = () => {
  // const account = localStorage.getItem('account');
  const { account } = useWeb3React();
  console.log('onreload account: ', account);
  if (!(account && account !== '' && account !== undefined)) {
    return <Navigate to={RoutePath.ROOT} />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
