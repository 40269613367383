import offerThum1 from '../../../../assets/images/offers/offers-thumb-001.jpg';
import offerThum2 from '../../../../assets/images/offers/offers-thumb-002.png';
import Slider from 'react-slick';

interface HeaderTextPropsType {
  title: string;
  subtitle: string;
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const OfferImagebanner = (props: any) => {
  return (
    <div>
      <div className="row justify-content-md-center offersSlider mt-3 mt-lg-4 ">
        <Slider {...settings}>
          <div className="col text-center">
            <img src={offerThum1} alt="CBWC NFT" className="img-fluid" />
          </div>
          <div className="col text-center">
            <img src={offerThum2} alt="CBWC NFT" className="img-fluid" />
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default OfferImagebanner;
