import React from 'react';
import ProfileMenu from './ProfileMenu';
import notificImg from '../../../../assets/images/notification-icon-white-bg.svg';
import NotificationsList from '../Notifications/NotificationsList';
import { StateContext } from '../../../../context/context';

const NotificationsWeb = (props: any) => {
  const { notifications = [] }: any = React.useContext(StateContext);

  return (
    <div className="text-end justify-content-end order-lg-last userNavigation d-none d-lg-block">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item dropdown">
          <g
            className="nav-link"
            id="notification"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img src={notificImg} alt="Notification" />
            {notifications.length > 0 && (
              <span className="notificationAlert"></span>
            )}
          </g>
          <ul className="dropdown-menu" aria-labelledby="notification">
            <li>
              <NotificationsList />
            </li>
          </ul>
        </li>

        <ProfileMenu />
      </ul>
    </div>
  );
};

export default NotificationsWeb;
