import fisr1 from "../../../../assets/images/fi-sr-gem-copie.svg";
import fisr2 from "../../../../assets/images/fi-sr-lock.svg";
import nount from "../../../../assets/images/noun-treasure-chest-1892735.svg";
import fisr3 from "../../../../assets/images/fi-sr-union.svg";
import DLogo from "../../../../assets/images/D-Logo.svg";
import bear0 from "../../../../assets/images/bear-005.png";
import fisr4 from "../../../../assets/images/fi-sr-gem-copie.svg";
import fisr5 from "../../../../assets/images/fi-sr-lock.svg";
import nount2 from "../../../../assets/images/noun-treasure-chest-1892735.svg";
import fisr6 from "../../../../assets/images/fi-sr-union.svg";
import DLogo2 from "../../../../assets/images/D-Logo.svg";

const SpecialNft = () => {
  return (
    <section id="specialNFTSection">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-7 text-lg-start col-xxl-7 pe-lg-0 order-lg-last contentBox">
            <h1 className="w-75" data-aos="fade-up">
              <span>WHY IS YOUR</span>
              <br />
              NFT SPECIAL?
            </h1>
            <div className="d-none d-lg-block">
              <p className="mt-3 mt-lg-5" data-aos="fade-left">
                Your NFT is special because no one else owns it. Owning one means you are special because not a creature that has walked (or crawled) this planet will have yours. Each NFT is specially hand-drawn and programmatically generated, so each one is 100% different. With the amount of special traits included – characters, watches, colors, functions etc., each piece is a distinct work of art that lives on its own.
              </p>
              <p className="mt-2 mt-lg-3" data-aos="fade-left">
                All Arkouda watches are stored as ERC-721 tokens on the Ethereum Blockchain. To access Members-Only Areas, Arkouda NFT holders must sign into their wallet. The supply of each NFT varies. There's also a selection of Super Rares which creates an even greater level of value.
              </p>

              <h3 className="mt-3 mt-lg-5 mb-3" data-aos="fade-up">
                WHAT YOUR MEMBERSHIP GETS YOU
              </h3>

              <div className="row membershipGet" data-aos="fade-up">
                <div className="col text-center">
                  <img src={fisr1} alt="CRYPTOBEARS" />
                  <p className="mt-2 mt-lg-3">
                    3,010 RARE
                    <br />
                    Arkouda
                  </p>
                </div>
                <div className="col text-center">
                  <img src={fisr2} alt="RIGHTS TO YOUR NFTS ASSET" />
                  <p className="mt-2 mt-lg-3">
                    FULL OWNERSHIP
                    <br />
                    RIGHTS TO YOUR
                    <br />
                    NFT ASSETS
                  </p>
                </div>
                <div className="col text-center">
                  <img src={nount} alt="EXCLUSIVE AIRDROPS" />
                  <p className="mt-2 mt-lg-3">
                    EXCLUSIVE AIRDROPS
                    <br />
                    THROUGHOUT
                    <br />
                    YOUR JOURNEY
                  </p>
                </div>
                <div className="col text-center">
                  <img src={fisr3} alt="REDEEMABLE" />
                  <p className="mt-2 mt-lg-3">
                    MONTHLY DROPS OF
                    <br />
                    REDEEMABLE ARKOUDA
                    <br />
                    UTILITY TOKENS
                  </p>
                </div>
                {/* <div className="col text-center">
                  <img src={DLogo} alt="DISTRIBUTION" />
                  <p className="mt-2 mt-lg-3">
                    DAMAC
                    <br />
                    DISCOUNTS
                  </p>
                </div> */}
              </div>
              <div className="row" data-aos="fade-up">
                <div className="col-12">
                  <a
                    href="https://opensea.io/collection/thecryptobearwatchclub"
                    target="_blank"
                    rel="noreferrer"
                    className="btnPrimary mt-2"
                  >
                    Get Yours Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-12" data-aos="fade-right">
            <div className="nftSpecial">
              <img src={bear0} alt="Bear" className="nftSpecialKV img-fluid" />
              <div className="backgroundMixNFT"></div>
            </div>
          </div>
          <div className="d-block d-lg-none text-center">
            <p className="mt-3 mt-lg-5 text-start" data-aos="fade-left">
              Your NFT is special because no one else owns it. Owning one means you are special because not a creature that has walked (or crawled) this planet will have yours. Each NFT is specially hand-drawn and programmatically generated, so each one is 100% different. With the amount of special traits included – characters, watches, colors, functions etc., each piece is a distinct work of art that lives on its own.
            </p>
            <p className="mt-3 mt-lg-5 text-start" data-aos="fade-left">
              All Arkouda watches are stored as ERC-721 tokens on the Ethereum Blockchain. To access Members-Only Areas, Arkouda NFT holders must sign into their wallet. The supply of each NFT varies. There's also a selection of Super Rares which creates an even greater level of value.
            </p>

            <h3 className="mt-3 mt-lg-5 mb-3" data-aos="fade-right">
              WHAT YOUR MEMBERSHIP GETS YOU:
            </h3>

            <div className="row membershipGet" data-aos="fade-up">
              <div className="col-6 col-sm-4 text-center">
                <img src={fisr4} alt="CRYPTOBEARS" />
                <p className="mt-2 mt-lg-3">
                  3,010 RARE
                  <br />
                  Arkouda
                </p>
              </div>
              <div className="col-6 col-sm-4 text-center">
                <img src={fisr5} alt="RIGHTS TO YOUR NFTS ASSET" />
                <p className="mt-2 mt-lg-3">
                  FULL OWNERSHIP
                  <br />
                  RIGHTS TO YOUR
                  <br />
                  NFT ASSETS
                </p>
              </div>
              <div className="col-6 col-sm-4 offset-sm-2 text-center">
                <img src={nount2} alt="EXCLUSIVE AIRDROPS" />
                <p className="mt-2 mt-lg-3">
                  EXCLUSIVE AIRDROPS
                  <br />
                  THROUGHOUT
                  <br />
                  YOUR JOURNEY
                </p>
              </div>
              <div className="col-6 col-sm-4 offset-sm-2 text-center">
                <img src={fisr6} alt="REDEEMABLE" />
                <p className="mt-2 mt-lg-3">
                  MONTHLY DROPS OF
                  <br />
                  REDEEMABLE ARKOUDA
                  <br />
                  UTILITY TOKENS
                </p>
              </div>

              {/* <div className="col-6 offset-3 col-sm-4 offset-sm-0 text-center">
                <img src={DLogo2} alt="DISTRIBUTION" />
                <p className="mt-2 mt-lg-3">
                  DAMAC
                  <br />
                  DISCOUNTS
                </p>
              </div> */}
            </div>
            <div className="row" data-aos="fade-up">
              <div className="col-12">
                <a
                  href="https://opensea.io/collection/thecryptobearwatchclub"
                  target="_blank"
                  rel="noreferrer"
                  className="btnPrimary mt-2"
                >
                  Get Yours Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SpecialNft;
