import { ProgressBar } from 'react-bootstrap';

interface PogressLineBarProps {
  val: number;
  traitType: string;
  traitValue: string;
  maxTraitSum:number;
}

const percentage = (partialValue) => {
  return (100 * partialValue) / 3010;
};

const PogressLineBar = (props: PogressLineBarProps) => {
  const { val = 0, traitType, traitValue , maxTraitSum = 100} = props;

  return (
    <div className="mt-2">
      <div style={{display:"inline"}}>
        {/* <h6>{traitType}</h6>  */}
        
        <p> {traitType} : { traitValue}</p>
      </div>
      <ProgressBar now={val} label={`${val}`} max={maxTraitSum} />
    </div>
  );
};

export default PogressLineBar;
