import { useEffect, useReducer } from 'react';
import RoutesDeclration from './router';
import AOS from 'aos';
// import AppErrorBoundry from './utils/AppErrorBoundry';
import { AppContextReducer } from './context/reducers/appContextReducer';
import { StateContext, DispatchContext } from './context/context';

const App = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const [appContextState, appContectDispatch] = useReducer(AppContextReducer, {
    notifications: [
      {
        id: '12',
        title: 'Welcome back',
        subtitle: 'Thank you for coming back, Have a nice day',
      },
    ],
    walletResponse: {},
    pieceResponse: {},
    offerResponse: {},
    cartItems: [],
    sizeSlected: 'Small',
  });

  return (
    <DispatchContext.Provider value={appContectDispatch}>
      <StateContext.Provider value={appContextState}>
        <RoutesDeclration />
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export default App;
