import { FunctionComponent } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import LandingSite from "../features/landing-site";
import NewsDetails from "../features/landing-site/component/News/NewsDetails";
import NewsLanding from "../features/landing-site/component/News/NewsLanding";
import TokenMonics from "../features/landing-site/component/TokenMonics";
import Layout from "../features/member-portal/components/Layout/Layout";
import ConnectToWallet from "../features/member-portal/connect";
import Dashboard from "../features/member-portal/dashboard/Index";
import Forge from "../features/member-portal/Forge/index";
import OfferPage from "../features/member-portal/offer/Index";
import OfferDetailPages from "../features/member-portal/offerDetail/Index";
import Staking from "../features/member-portal/staking";
import ProtectedRoute from "./ProtectedRoute";
import * as RoutePath from "./config";
import ShopLanding from "../features/member-portal/shop/Index";
import ShopDetails from "../features/member-portal/shop/shopDetail";
import ShopCart from "../features/member-portal/shop/shopCart";
import CheckoutShipping from "../features/member-portal/shop/shiping";

const RoutesDeclration: FunctionComponent = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={RoutePath.ROOT} element={<LandingSite />} />
        <Route path={RoutePath.CONNECT} element={<ConnectToWallet />} />
        <Route path={RoutePath.TOKEN_MONICS} element={<TokenMonics />} />
        <Route path={RoutePath.NEWS_BLOGS} element={<NewsLanding />} />
        <Route path={RoutePath.NEWS_DETAILS} element={<NewsDetails />} />

        <Route path={RoutePath.MEMBER_DASHBOARD} element={<ProtectedRoute />}>
          <Route
            path={RoutePath.MEMBER_DASHBOARD}
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />
        </Route>

        {/* route to forge watch page */}
        <Route path={RoutePath.MEMBER_FORGE} element={<ProtectedRoute />}>
          <Route
            path={RoutePath.MEMBER_FORGE}
            element={
              <Layout>
                <Forge />
              </Layout>
            }
          />
        </Route>

        <Route path={RoutePath.MEMBER_STAKING} element={<ProtectedRoute />}>
          <Route
            path={RoutePath.MEMBER_STAKING}
            element={
              <Layout>
                <Staking />
              </Layout>
            }
          />
        </Route>

        <Route path={RoutePath.MEMBER_OFFER} element={<ProtectedRoute />}>
          <Route
            path={RoutePath.MEMBER_OFFER}
            element={
              <Layout>
                <OfferPage />
              </Layout>
            }
          />
        </Route>

        <Route path={RoutePath.MEMBER_OFFER} element={<ProtectedRoute />}>
          <Route
            path={RoutePath.MEMBER_OFFER}
            element={
              <Layout>
                <OfferPage />
              </Layout>
            }
          />
        </Route>

        <Route
          path={RoutePath.MEMBER_OFFER_DETAIL}
          element={<ProtectedRoute />}
        >
          <Route
            path={RoutePath.MEMBER_OFFER_DETAIL}
            element={
              <Layout>
                <OfferDetailPages />
              </Layout>
            }
          />
        </Route>

        <Route path="*" element={<Navigate to={RoutePath.ROOT} replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesDeclration;
