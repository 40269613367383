const crypto = require("crypto")

const ENC_KEY = process.env.REACT_APP_ENC_KEY
const IV = process.env.REACT_APP_ENC_IV
const HC_ENC_KEY = process.env.REACT_APP_HC_ENC_KEY
exports.encrypt = val => {
  let cipher = crypto.createCipheriv("aes-256-cbc", ENC_KEY, IV)
  let encrypted = cipher.update(val, "utf8", "base64")
  encrypted += cipher.final("base64")
  return encrypted
}

exports.decrypt = encrypted => {
  let decipher = crypto.createDecipheriv("aes-256-cbc", ENC_KEY, IV)
  let decrypted = decipher.update(encrypted, "base64", "utf8")
  return decrypted + decipher.final("utf8")
}

exports.decryptHEX = encrypted => {
  const _iv = Buffer.alloc(16, 0)
  let decipher = crypto.createDecipheriv("aes-128-cbc", HC_ENC_KEY, _iv)
  let decrypted = decipher.update(encrypted, "hex", "utf8")
  return decrypted + decipher.final("utf8")
}
