import { useEffect, useState, useContext } from "react";
import Loader from "../../../common/loader";
import { getNftStats, getUserWalletDetails } from "../../../services/nodeapi";
import HeaderText from "../components/HeaderText/HeaderText";
import NftCardSimple from "../components/NftCard/NftCardSimple";
import StatsCard from "../components/StatsCard/StatsCard";
import Slider from "react-slick";
import OfferImagebanner from "../components/OfferImagebanner/OfferImagebanner";
import { useNavigate } from "react-router-dom";
import * as RouterPath from "../../../router/config";
import { RenderAllOfferCards } from "../offer/Index";
import { getAllOffers } from "../../../services/offersAPI";
import { useCustomWeb3React } from "../../../hooks/useCustomWeb3React";
import StatsCardOfProject from "../components/StatsCard/StatsCardOfProject";
import tshirt from "../../../assets/images/CBWC-tShirt-001.png";
import tshirtShow from "../../../assets/images/tshirt-show.png";

import noNftimg from "../../../assets/images/no-nft-icon.svg";

import MyWatchesComponent from "../components/Forge/MyWatches/myWatchesComponent";
import GetWatchesService from "../../../services/forge/getWatchesService";
import MyPiecesComponent from "../components/Forge/MyPieces/myPiecesComponent";
import GetPiecesService from "../../../services/forge/getPiecesService";
import { DispatchContext, StateContext } from "../../../context/context";
import * as appContextAction from "../../../context/actions/appContextActions";
import _ from "lodash";
import { Placeholder } from "react-bootstrap";

const settings = {
  dots: false,
  lazyLoad: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
};

const settingsStatsSlider = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Dashboard = (props: any) => {
  const [account, setAccount] = useState("");
  const [loading, setLoading] = useState(false);
  const [offerLoading, setOfferLoading] = useState(false);
  const [piecesLoading, setPiecesLoading] = useState(false);

  const { walletResponse, pieceResponse, offerResponse }: any =
    useContext(StateContext);

  const [offers, setOffers] = useState([]);
  const [formattedccount, setFormattedAccount] = useState("");
  const [resp, setResp] = useState({});
  const [nft, setNfts] = useState([]);
  let navigate = useNavigate();
  const { account: accountWeb3 } = useCustomWeb3React();
  const [myWatches, setMyWatches] = useState([]);
  const [myPieces, setMyPieces] = useState([]);
  const appContextDispatch = useContext(DispatchContext);

  useEffect(() => {
    setAccount(accountWeb3);
    async function offerOnload() {
      setOfferLoading(true);

      const offersData = _.isEmpty(offerResponse)
        ? await getAllOffers()
        : offerResponse;
      if (_.isEmpty(offerResponse)) {
        appContextDispatch(appContextAction.offerResponse(offersData));
      }

      setOffers([offersData[0], offersData[3], offersData[2]]);

      setOfferLoading(false);
    }

    if (!resp.hasOwnProperty("total_volume")) {
      getStats();
    }

    offerOnload();
  }, [resp, accountWeb3]);

  useEffect(() => {
    async function geStatsDetails() {
      try {
        if (account && account.length > 0 && nft && nft.length === 0) {
          setLoading(true);
          const mResp = await getUserWalletDetails(account, true);
          appContextDispatch(appContextAction.walletDetailResponse(mResp));
          setNfts(mResp.data.resp.data.users[0].nfts);
          setLoading(false);

          setPiecesLoading(true);
          //Load Pieces y watches to forge components in the dashboard
          const watches = await GetWatchesService(account);
          // const pieces = await GetPiecesService(account);

          const pieces = _.isEmpty(pieceResponse)
            ? await GetPiecesService(account)
            : pieceResponse;
          if (_.isEmpty(pieceResponse)) {
            appContextDispatch(appContextAction.piecesResponse(pieces));
          }
          setPiecesLoading(false);
          setMyWatches(watches);
          setMyPieces(pieces);
        }
      } catch (error) {
        setLoading(false);
      }
    }

    if (account && account !== undefined && formattedccount === "") {
      setFormattedAccount(
        account.substring(0, 4) +
          "...." +
          account.substring(account.length - 3, account.length)
      );
    }

    geStatsDetails();
  }, [account, nft]);

  const getStats = async () => {
    await getNftStats()
      .then(function (response: any) {
        const { data = {} } = response;
        const { resp, error, success } = data;

        if (success) {
          setResp(resp);
        }
      })
      .catch(function (error: any) {})
      .then(function () {
        // always executed
      });
  };

  const onViewAllOffer = () => {
    navigate(RouterPath.MEMBER_OFFER);
  };

  const onViewAllNFT = () => {
    navigate(RouterPath.MEMBER_STAKING);
  };

  return (
    <div className="stakingSection mt-4 mb-5">
      <HeaderText
        title="DASHBOARD"
        subtitle={`HI ${formattedccount}, WELCOME BACK!`}
      />

      <div className="container">
        <div className="row mt-3 mt-lg-4">
          <div className="col-12">
            <Slider
              className="row justify-content-md-center offersSlider dashboardnftSlider"
              {...settingsStatsSlider}
            >
              <StatsCard showTitle />
              <StatsCardOfProject openStats={resp} />
            </Slider>
          </div>
        </div>
      </div>

      <div className="container mt-3 mt-lg-4">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="p3 roundedBox secondaryBG h-100">
              <div className="headingOpt">
                <h4 className="float-start">MY NFTS</h4>
                {nft.length > 0 && (
                  <div
                    onClick={onViewAllNFT}
                    className="float-end thirdBG text-decoration-none viewallCustom"
                  >
                    View all
                  </div>
                )}
                <div className="clearfix"></div>
              </div>
              <div>
                {!loading && (
                  <Slider
                    className="row justify-content-md-center myNFTslider mt-3 mt-lg-4 slick-initialized slick-slider slick-dotted"
                    {...settings}
                  >
                    {nft.length > 0 &&
                      nft.map((eachNft) => {
                        return (
                          <NftCardSimple
                            id={eachNft?.id}
                            tier={eachNft.tier}
                            openStats={resp}
                          />
                        );
                      })}
                  </Slider>
                )}
                {loading && (
                  <div className="loadCenter">
                    <Loader enable={true} />
                  </div>
                )}
              </div>
              {/* {nft.length === 0 && !loading && (
                <div className="text-center position-relative h-100">
                  <div className="comingsoonClaimed">
                    <div className="verticallyCenter">
                      <div className="verticalContentBox noNFT">
                        <div className="row justify-content-center text-center">
                          <div className="col-12 text-center m-3 m-lg-4">
                            <img
                              src={noNftimg}
                              alt="OFFERS"
                              className="noNFTIcon"
                            />
                          </div>
                          <div className="col-12 text-center">
                            <h5>
                              UH-OH! YOUR BEARS SEEM LOST IN THE ARKOUDA JUNGLE
                              AND ARE NOWHERE TO BE FOUND.
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
          {/* <div className="col-12 col-lg-6 mt-3 mt-lg-0">
            <div className="p3 roundedBox secondaryBG h-100">
              <div className="headingOpt w-100">
                <h4 className="float-start">CBWC SHOP</h4>
                <div className="clearfix"></div>
              </div>
              <div className="row mt-3 mt-lg-5">
                <div className="col-12 col-lg-6 text-center">
                  <img src={tshirt} alt="tShirt" className="img-fluid" />
                </div>
                <div className="col-12 col-lg-6">
                  <div className="comingSoonShop text-center text-lg-start mt-3 mt-lg-0">
                    <h3>COMING SOON</h3>
                    <h5>USE YOUR TOKENS TO BUY CBWC EXCLUSIVE MERCHANDISE</h5>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/*Components from forge*/}
      <div className="container mt-3 mt-lg-4">
        {myWatches.length > 0 ? (
          <MyWatchesComponent myWatches={myWatches} />
        ) : null}
      </div>
      <div className="container mt-3 mt-lg-4">
        {myPieces.length > 0 ? (
          <MyPiecesComponent myPieces={myPieces} isForging={false} />
        ) : null}
      </div>

      {piecesLoading && (
        <div className="container mt-3 mt-lg-4">
          <Placeholder size="lg" as="p" animation="glow">
            <Placeholder style={{ height: "100px" }} xs={12} />
          </Placeholder>
        </div>
      )}

      {/* <div className="container">
        <div className="row mt-3 mt-lg-5">
          <div className="col-12">
            <div className="headingOpt">
              <h4 className="float-start">OFFERS</h4>
              <div
                onClick={onViewAllOffer}
                className="float-end thirdBG text-decoration-none viewallCustom"
              >
                VIEW ALL OFFERS
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
        <div className="row mt-3 mt-lg-4 g-3">
          <RenderAllOfferCards filteredOffer={offers} />
        </div>
      </div> */}

      <div className="container">
        {/* <div className="row mt-3 mt-lg-4">
          <div className="col-12">
            <div className="headingOpt">
              <h4 className="float-start">NEWS AND BLOGS</h4>
              <div
                className="float-end thirdBG text-decoration-none viewallCustom"
                onClick={onViewAllOffer}
              >
                View all
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div> */}
        {/* <div className="row mt-1">
          <div className="col-12">
            <OfferImagebanner />
          </div>
        </div> */}
      </div>

      {/* <Loader enable={true}/> */}
    </div>
  );
};

export default Dashboard;

// 1. persisit the data
// 2. total balance of contract
