import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActivateWallet } from '../../../hooks';
import {
  switchMessage,
  walletConnectedMessageError,
  walletConnectedZerobalance,
} from '../../../hooks/swal2';
import { useCustomWeb3React } from '../../../hooks/useCustomWeb3React';
import { walletList } from '../../../utils/web3Connectors';
import * as RouterPath from '../../../router/config';
import { getUserWalletDetails } from '../../../services/nodeapi';
import mmeberWallet from '../../../assets/images/memberLoginBear.png';
import metaIcon from '../../../assets/images/metamask-icon.svg';
import walleticon from '../../../assets/images/trust-wallet-icon.svg';
import baseIcon from '../../../assets/images/coin-base-icon.svg';
import PageLoader from '../../../common/loader/PageLoader';
import { DispatchContext } from '../../../context/context';
import * as appContextAction from "../../../context/actions/appContextActions";
import _ from "lodash"
import { CHAIN_ID } from '../../../constants/constants';


const ConnectToWallet = () => {
  const [loading, setLoading] = useState(false);
  const { account, deactivate, chainId } = useCustomWeb3React();
  const appContextDispatch = useContext(DispatchContext);
  const activateWallet = useActivateWallet();
  let navigate = useNavigate();

  useEffect(() => {
    async function geStatsDetails() {
      try {
        if (!_.isEmpty(account)) {
          setLoading(true);
          const mResp = await getUserWalletDetails(account, true);

          const { users = [] } = mResp?.data?.resp?.data;
          if (users.length > 0) {
            const { totalNftCount = 0 } = users[0];
            if (totalNftCount > 0) {
              appContextDispatch(appContextAction.walletDetailResponse(mResp));
              navigate(RouterPath.MEMBER_DASHBOARD);
            } else {
              await deactivate();
              walletConnectedZerobalance();
            }
          } else {
            await deactivate();
            walletConnectedZerobalance();
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
    if (chainId && chainId !== CHAIN_ID) {
      switchMessage(chainId);
    } else {
      geStatsDetails();
    }
  }, [account]);


  const onMetamskClick = async () => {
    setLoading(true);
    await activateWallet(walletList[0].connector, (e) => {
      walletConnectedMessageError(e);
      if (!e) {
        setLoading(false);

        return;
      }
      setLoading(false);
    });
  };

  const onTrustWalletConnect = async () => {
    setLoading(true);

    await activateWallet(walletList[1].connector, (e) => {
      walletConnectedMessageError(e);

      if (!e) {
        setLoading(false);

        return;
      }

      setLoading(false);
    });
  };

  const onCoineBaseConnect = async () => {
    setLoading(true);
    await activateWallet(walletList[2].connector, (e) => {
      walletConnectedMessageError(e);

      if (!e) {
        setLoading(false);
        return;
      }

      setLoading(false);
    });
  };

  return (
    <div className="adminSection">
      <div className="memberLoginSection">
        <div className="container">


          <div className="row justify-content-md-center">
            <div className="col-12 col-lg-7 text-center memberLoginLftSection">
              <h1 data-aos="fade-up" className="">
                ARKOUDA
                {/* <br />
                WATCH CLUB */}
              </h1>
              <div className="text-center" data-aos="fade-up">
                <img
                  src={mmeberWallet}
                  alt="MEMBER"
                  className="img-fluid memberImg"
                />
              </div>
            </div>
            <div className="col-12 col-lg-5 text-center position-relative">
              <div className="connectWallet text-center">
                <h3>CONNECT YOUR WALLET TO LOGIN</h3>
                <div className="connect-btn" onClick={onMetamskClick}>
                  <img src={metaIcon} alt="METAMASK" />
                  METAMASK
                </div>

                <div className="connect-btn" onClick={onTrustWalletConnect}>
                  <img src={walleticon} alt="TRUST WALLET" />
                  TRUST WALLET
                </div>

                <div className="connect-btn" onClick={onCoineBaseConnect}>
                  <img src={baseIcon} alt="COIN BASE" />
                  COIN BASE
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fadedBackgroundBlack"></div>
      <PageLoader enable={loading} />
    </div>
  );
};

export default ConnectToWallet;

//Todo:
// - page loader
