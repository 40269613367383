import React from "react"

const PieceComponent = React.memo((props:any) =>{

  function addPiece(tokenId) {
    props.onAdd(tokenId);
  }

  function removePiece(tokenId) {
    props.onRemove(tokenId);
  }

  return (
    <div>
      <div className="pb-4">
        <div className="text-decoration-none">
          {props.isForging && props.isSelected === true ? (
            <div className="">
              <div>
                <video src={props.image} controls className="img-fluid" />
              </div>
              <div className="pieceComponentForge pieceComponent">
                <div className="row contentPiece">
                  <div className="col-12">
                    <h3 className="pieceName">{props.name}</h3>
                    <div className="row">
                    <div className="col-12">
                      <p className="pieceType">{props.type}</p>
                    </div>
                  </div>
                  </div>
                </div>
                <div className="mt-3">
                  <div
                    onClick={() => removePiece(props.tokenId)}
                    className="w-100  btnSecondary"
                  >
                    REMOVE
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div  className={` ${props.isValidForCombination === false ? "maskPiece" : ""}`}>
              <div className="">
                <video src={props.image} controls className="img-fluid" />
              </div>
              <div className="pieceComponent">
              <div className="row contentPiece">
                  <div className="col-12">
                    <h3 className="pieceName">{props.name}</h3>
                    <div className="row">
                    <div className="col-12">
                      <p className="pieceType">{props.type}</p>
                    </div>
                  </div>
                  </div>
                </div>
                {props.isForging === true ? (
                  props.countPiecesToForge < 3 &&
                  props.isValidForCombination === true ? (
                    <div className="mt-3">
                      <div
                        onClick={() => addPiece(props.tokenId)}
                        className="w-100  btnSecondary"
                      >
                        ADD TO FORGE
                      </div>
                    </div>
                  ) : (
                    <div className="w-100  btnPrimary">INCOMPATIBLE</div>
                  )
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
})

export default PieceComponent;
