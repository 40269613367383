export default function ViewModel3DComponent(props) {

  function handleStatus() {
    props.handleStatus();
  }

  const uid = props.uid3D;
  const frame ="https://sketchfab.com/models/" +uid+"/embed?autostart=1&camera=0&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_watermark_link=0&ui_watermark=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_theme=dark&dnt=1";

  return (
    <div className="modal display-block">
      <div className="modal-main">
        <div className="row containerIframe">
          <iframe title="3d-viewer" src={frame}></iframe>
        </div>
        <div onClick={() => handleStatus()} className="float-end btnPrimary  text-decoration-none ">
          Close
        </div>
      </div>
    </div>
  );
}
