import dashboardIcon from "../assets/images/navigationIcons/dashboard-icon-white.svg";
import shopIcon from "../assets/images/navigationIcons/shop-icon-white.svg";
import offersIcon from "../assets/images/navigationIcons/offers-icon-white.svg";
import stakingIcon from "../assets/images/navigationIcons/staking-icon-white.svg";
import watchIcon from "../assets/images/navigationIcons/watch-generator-icon-white.svg";
import * as RoutePath from "../router/config";

export const SIDEBAR_MEMBER_PORTAL_OPTIONS = [
  {
    name: "DASHBOARD",
    route: RoutePath.MEMBER_DASHBOARD,
    imgSrc: dashboardIcon,
    activeON: [RoutePath.MEMBER_DASHBOARD]
  },
  // {
  //   name: "OFFERS",
  //   route: RoutePath.MEMBER_OFFER,
  //   imgSrc: offersIcon,
  //   activeON: [RoutePath.MEMBER_OFFER, RoutePath.MEMBER_OFFER_DETAIL]
  // },
  {
    name: "STAKING",
    route: RoutePath.MEMBER_STAKING,
    imgSrc: stakingIcon,
    activeON: [RoutePath.MEMBER_STAKING]
  },
  {
    name: "GENERATOR",
    route: RoutePath.MEMBER_FORGE,
    imgSrc: watchIcon,
    activeON: [RoutePath.MEMBER_FORGE]
  },
];

export const SLICK_SETTINGS_RESPONSIVE = {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true
      }
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};



export const SLICK_SETTINGS_SHOP = {
  dots: true,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1
};

export const ROADMAP_PANNEL_SLICK_SETTINGS = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 6,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false
      }
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};


export const SIZEMAP = {
  Small: 'S',
  Medium: 'M',
  Large: 'L',
};

export const TOTAL_ALLOWED_ORDERS  = 2;


export const WHITELISTED_ACCOUNTS_SHOP = [
  "0x2da4Cf06d749402fFd7378674FE664c72b8C6109",
  "0x21B9a93d2DA68205fC6a0904B983E125E683CC1b",
  "0x424AC97fbB9a52Bd14372311cb7039E997DE6Cb1",
  "0x5A60AA883D28536246e9C108498FBC83d2A0F629",
  "0x2816C0Caa099DEfA64dc0534fc4306FEb73dcBbF",
  "0x0A7Bd536F83b8dEE1278e59659f0B9f34F9a04Df",
  "0x36f33160E5F03eFf0Cc8d1f54D7Ac640a705eFE1",
  "0x7Dc53E30cc95932225b1b9655B3005740FE2047a",
  "0x7F3A129A07e9123f18b2bC35feD01597fE8979e5",
  "0x6719964015cF43f294aA1B9DEEf2A8631d10e0b8",
  "0x25C9d7156d8c47ecf06854E0F49783C327e00db5",
  "0x34038ea329CBE21149636eFaa9A1e993129f6eBA",
  "0xedf69780EAEfcae1FB20022EeCe19faf8E4071c2",
  "0x22DD448986D0f00A60Fe5BF64DE0b2250f2860aF",
  "0xfd41cc97e5788051074bee1e5c1326e502599ba1",
  "0x95e9773079e6a0B5C473805bAAa2f631F2a83Bd2",
  "0x45eAbB3B78f36b1E956fA7865b0A1ff939FceDF3",
  "0xeD9D9E469A849928b6E48212AF743af884bA9845",
  "0x7e098c9616e4412e501a790dfbfcb0f2e8f4e2ec",
  "0x732501DCEc161A0aEC1ee4A8d7A7d7851FA82267",
  "0x52cec1866CdBb9daFC02DCc8ea062E2638556289",
  "0xE608794bc746d1Feedb5A8396882ED68652489e4",
  "0x06D9965DcA117e4Bfa222D0767d6E4F0AFd23F11",
  "0xed176ef200ac42bdddb95983815265b8063fca48",
  "0x8B85A67060CaD472ea4ED878e03629EEb164eFa2",
  "0x27AA98885847a37d69d8b89a046B77fc10525E79",
  "0x3CA60a1ce8634f68DEE0cbd7fA528b03f78810a9",
  "0x2435c0F276742C79CE8e85AF85966ea6D1A763f7",
  "0xFF7fAC5899762BcD05b347d83357C5bdcA9A9625",
  "0x73093BB18AA50D2628a430c8812583Cd55Ec0719",
  "0x438D9E9EC7E62a184d72D4536Defd234556A7404",
  "0x6Cd195A97De13Db156963C3CC0C100C223e75d84",
  "0xa59c2D2eF1c5062300C4c3dbfB3C1fd4244C4224",
  "0xa21Dc4E0601A52614DbA95b4b94c3a1Bd018C547",
  "0x5F2b00AbeB7f48926fbDCEC1793c6d78d3dd599B",
  "0xc64AEb91D0ebdFf788d10D595D02Db1ad287C371",
  "0x49C4C7b874570FD13bc7F9Eb1BcF0DDAAd8dDF9B",
  "0x92F1B7e92332aEE61972E03c96cD5f23bCe8cFCb",
  "0x403645849A9f33C1d5Deb9d6fD051c33E5a4A349",
  "0x2F9EC99B520d2b730748dD2dC869796Bc4f67404",
  "0xd020C0Af3d2369025c08a1786Ef15298C5bD372F",
  "0xE726cBE093E31D64EC04c8f81f00Eea5Cd43A831",
  "0xd979AF6EB11C222A3687e0dB9e3D38508EBb8402",
  "0x02A8a0376337BE6124eaA280C8B4aAC0dD96bD91",
]
