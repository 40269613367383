import React, { useEffect, useState } from 'react';
import Loader from '../../../../common/loader';
import { CONTRACT_ARKODA_ADDRESS_PROD } from '../../../../constants/constants';
import { cbwcTotalStacked } from '../../../../contracts/functions/nftContract';
import {
  totalSupplyArkoda,
} from '../../../../contracts/functions/stakingContract';
import { convertToInternationalCurrencySystem } from '../../../../utils/convertToInternationalCurrencySystem';
import { toEther } from '../../../../utils/formating';
import openSeaImg from "../../../../assets/images/OpenSea.svg"

const options: any = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const StatsCardOfProject = (props: any) => {
  const { openStats } = props;
  const [respStaking, setRespStaking]: any = useState();
  const [loadingStaking, setLoadingStaking] = useState(false);
  const [totalSupplyArk, setTotalSupplyArk] = useState('0');

  const today = new Date();
  const todaysDate = today.toLocaleDateString('en-US', options);

  const getTotalRedeemed = async () => {
    const total_supply_of_contract = toEther(await totalSupplyArkoda());
    setTotalSupplyArk(total_supply_of_contract);
  };

  useEffect(() => {
    async function geStatsDetails() {
      try {
        setLoadingStaking(true);
        const mResp = await cbwcTotalStacked(CONTRACT_ARKODA_ADDRESS_PROD);
        setRespStaking(mResp);
        setLoadingStaking(false);
      } catch (error) {
        setLoadingStaking(false);
      }
    }
    geStatsDetails();
    getTotalRedeemed();
  }, []);

  const navigateToOpensea = () => {
    // "https://opensea.io/collection/thecryptobearwatchclub"
    // in blank
  };

  return (
    <div className="col-12 text-center">
      <div className="nftCollections">
        <div className="row">
          <div className="col-12">
            <div className="headingOpt">
              <h3 className="text-start title float-start">Arkouda NFTS</h3>
              <a
                href="https://opensea.io/collection/thecryptobearwatchclub"
                target="_blank"
                rel="noreferrer"
                className="float-end text-decoration-none btnPrimary openseaBtnNFT"
              >
                <img src={openSeaImg} alt="OpenSea" />
                BUY ON OPENSEA
              </a>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
        <div className="row pt-lg-3">
          <div className="col-12 col-lg-3 text-center bRight">
            <div className="row">
              <div className="col-6 col-lg-12">
                <h3 className="text-start text-lg-center">
                  NUMBER <span className="d-inline d-lg-block">OF NFTS</span>
                </h3>
              </div>
              <div className="col-6 col-lg-12">
                <h2 className="mt-0 mt-lg-4 mb-1 mb-lg-4 text-end text-lg-center">
                  3010
                </h2>
                {/* <h6 className="text-end text-lg-center">45% THIS WEEK</h6> */}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 text-center bRight">
            <div className="row">
              <div className="col-6 col-lg-12">
                <h3 className="text-start text-lg-center">
                  NUMBER OF{' '}
                  <span className="d-inline d-lg-block">STAKED NFTS</span>
                </h3>
              </div>
              <div className="col-6 col-lg-12">
                <h2 className="mt-0 mt-lg-4 mb-1 mb-lg-4 text-end text-lg-center">
                  <Loader enable={loadingStaking} />
                  {!loadingStaking && respStaking}
                </h2>
                {/* <h6 className="text-end text-lg-center">45% THIS WEEK</h6> */}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 text-center bRight">
            <div className="row">
              <div className="col-6 col-lg-12">
                <h3 className="text-start text-lg-center">
                  TOTAL REDEEMED{' '}
                  <span className="d-inline d-lg-block">TOKENS</span>
                </h3>
              </div>
              <div className="col-6 col-lg-12">
                <h2 className="mt-0 mt-lg-4 mb-1 mb-lg-4 text-end text-lg-center">
                  <Loader enable={loadingStaking} />

                  {!loadingStaking && parseFloat(totalSupplyArk).toFixed(2)}
                </h2>
                {/* <h6 className="text-end text-lg-center">45% THIS WEEK</h6> */}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 text-center bRight">
            <div className="row">
              <div className="col-6 col-lg-12">
                <h3 className="text-start text-lg-center">
                  TOTAL VOLUME{' '}
                  <span className="d-inline d-lg-block">TRADED</span>
                </h3>
              </div>
              <div className="col-6 col-lg-12">
                <h2 className="mt-0 mt-lg-4 mb-1 mb-lg-4 text-end text-lg-center">
                  <Loader enable={loadingStaking} />

                  {openStats &&
                    openStats.hasOwnProperty('total_volume') &&
                    '$' +
                    convertToInternationalCurrencySystem(
                      parseFloat(openStats?.total_volume)
                    )}
                </h2>
                {/* <h6 className="text-end text-lg-center">45% THIS WEEK</h6> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-lg-3 text-center mt-3 mt-lg-4 order-last">
            <h6>* LATEST DATA AS OF {todaysDate}</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsCardOfProject;
