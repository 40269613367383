import Web3 from 'web3';
import { CONTRACT_NFT_BEAR_ADDRESS_PROD } from '../constants/constants';

import NFTContractABI from './abis/NFTContract.json';

let web3Infura;
const infuraUrl =
  'https://mainnet.infura.io/v3/ab9939ceed0f48e49a721e1cd5ab5705';


export const nftContractMainnet = () => {
  try {
    web3Infura = new Web3(infuraUrl);
    return new web3Infura.eth.Contract(
      NFTContractABI,
      CONTRACT_NFT_BEAR_ADDRESS_PROD
    );
  } catch (e) {
    console.log('contract init', e);
  }
};
