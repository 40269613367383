import newdrop from "../../../../assets/images/newDrop.png"
import { useNavigate } from 'react-router-dom';
import { useCustomWeb3React } from '../../../../hooks/useCustomWeb3React';
import * as RouterPath from '../../../../router/config';

const NewDrop = () => {

  const { account } = useCustomWeb3React();
  const navigate = useNavigate();

  const GotoMyDashboard = () => {
    if (account) {
      navigate(RouterPath.MEMBER_FORGE);
    } else {
      navigate(RouterPath.CONNECT);
    }
  };

  return (
    <section id="newDropSection">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-5 text-lg-start col-xxl-5 pe-lg-0 order-lg-last">
            <h1 data-aos="fade-right">
              ARKOUDA
              <br />
              <span>
                NEW DROP<div className="textNew">NEW</div>
              </span>
            </h1>
            <p data-aos="fade-left" className="mt-5">
              This time, we're dialing up the intrigue. Our watches are broken
              down into three separate pieces as NFTs: the strap, dial and
              case. Collect all pieces of a specific set to forge your new NFT
            </p>

            <div
              className="hero-funfact my-5 d-none d-lg-block"
              data-aos="fade-left"
            >
              {/* <div className="row">
              <div className="col-12">
                <h5>
                  MISSED THE FIRST DROP? &nbsp;
                  <span className="secondaryColor d-inline-block">
                    BE THE FIRST FOR WHAT'S NEXT
                  </span>
                </h5>
              </div>
              <div className="col-12">
                <div onClick={GotoMyDashboard}  className="btnPrimary mt-2">
                  GET YOURS NOW
                </div>
              </div>
            </div> */}
            </div>
          </div>
          <div className="col-lg-6 highlighterBg">
            <div className="row g-3">
              <div className="col-12">
                <img
                  src={newdrop}
                  alt="NewDrop"
                  className="img-fluid"
                />
              </div>
            </div>
            <div
              className="hero-funfact mt-5 d-block d-lg-none text-center"
              data-aos="fade-left"
            >
              {/* <div className="row">
              <div className="col-12">
                <h5>
                  MISSED THE FIRST DROP?{' '}
                  <span className="secondaryColor d-inline-block">
                    BE THE FIRST FOR WHAT'S NEXT
                  </span>
                </h5>
              </div>
              <div className="col-12">
                <div  onClick={GotoMyDashboard}  className="btnPrimary mt-2">
                  GO TO FORGE 
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewDrop;
