import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LandingLayout from '../LandingLayout/LandingLayout';
import { getNewsDetails } from './getNewsDetails';
import * as RoutePath from '../../../../router/config';

const NewsDetails = (props: any) => {
  const { state }: any = useLocation();
  const { slug = '' } = state;

  const [newsDetailsResp, setNewsDetailsResp]: any = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (slug === '') {
      navigate(RoutePath.NEWS_BLOGS);
    }
    setLoading(true);
    getNewsDetails(slug).then((response) => {
      console.log('response: ', response);
      if (response) {
        setNewsDetailsResp(response);
      } else {
        navigate(RoutePath.NEWS_BLOGS);
      }
      setLoading(false);
    });
  }, []);

  // const { newsBy = {} } = newsDetails;
  // const { image = {}, moreDetails } = newsBy?.newsDetails;

  return (
    <div className="adminSection innerPage">
      <LandingLayout>
        {!loading && (
          <div className="innerBodySection news">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h2>{newsDetailsResp?.newsBy.title}</h2>

                  <div className="offersBlock p-0">
                    <h4>08/05/2022</h4>
                  </div>
                  <div className="br-20 mt-3 mt-lg-4">
                    <img
                      src={
                        newsDetailsResp?.newsBy?.newsDetails?.image
                          ?.mediaItemUrl
                      }
                      alt={newsDetailsResp?.newsBy?.newsDetails?.image?.altText}
                      className="img-fluid"
                    />
                  </div>
                  <div className="secondaryBG mt-3 mt-lg-5 p3 br-20">
                    {/* <p className="mb-3 mb-lg-4">{moreDetails}</p> */}
                    <div
                      className="mb-3 mb-lg-4"
                      dangerouslySetInnerHTML={{
                        __html:
                          newsDetailsResp?.newsBy?.newsDetails?.moreDetails,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="container">
            <div className="row mt-3 mt-lg-5">
              <div className="col-12">
                <div className="headingOpt">
                  <h3 className="float-start">SIMILAR NEWS</h3>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
            <div className="row g-3">
              <div className="col-12 col-md-6 col-lg-4">
                <div className="secondaryBG">
                  <a href="news-details.html" className="text-decoration-none">
                    <div>
                      <img
                        src="images/news-blogs/CBWC-Website-Blog-News-Story-01-v1-4.jpg"
                        className="img-fluid"
                        alt="CBWC"
                      />
                    </div>
                    <div className="offersBlock newsBlock">
                      <h3>THE LUCKY WINNER WHO TOOK HOME AN AUDEMARS PIGUET</h3>
                      <h4>08/05/2022</h4>
                      <p>
                        Great experiences are something why CBWC is becoming the
                        talk of the NFT world, especially with the giveaways
                        that we roll out. This time around, it was Bear 1361 who
                        walked away smiling as the raffle winner.
                      </p>
                      <div className="mt-3">
                        <a href="#" className="w-100 btnPrimary">
                          READ MORE
                        </a>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="secondaryBG">
                  <a href="news-details.html" className="text-decoration-none">
                    <div>
                      <img
                        src="images/news-blogs/CBWC-Website-Blog-News-Story-01-v1-3.jpg"
                        className="img-fluid"
                        alt="CBWC"
                      />
                    </div>
                    <div className="offersBlock newsBlock">
                      <h3>CBWC TEAMS UP WITH DAMAC FOR HOLDER REWARDS</h3>
                      <h4>08/05/2022</h4>
                      <p>
                        Fulfill the cravings of your palate, Hollywood style.
                      </p>
                      <div className="mt-3">
                        <a href="#" className="w-100 btnPrimary">
                          READ MORE
                        </a>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="secondaryBG">
                  <a href="news-details.html" className="text-decoration-none">
                    <div>
                      <img
                        src="images/news-blogs/CBWC-Website-Blog-Article-01-v1-1.jpg"
                        className="img-fluid"
                        alt="CBWC"
                      />
                    </div>
                    <div className="offersBlock newsBlock">
                      <h3>DAMAC MAISON COUR JARDIN</h3>
                      <h4>PRIVILEGED 50% OFF ROOM RATES</h4>
                      <p>
                        Revel in the luxury of interiors made for pure comfort.
                      </p>
                      <div className="mt-3">
                        <a href="#" className="w-100 btnPrimary">
                          READ MORE
                        </a>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div> */}
          </div>
        )}
      </LandingLayout>
    </div>
  );
};

export default NewsDetails;
