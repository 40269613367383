import { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Loader from '../../../common/loader';
import {
  availOffer,
  getAllOffers,
  getOfferDetails,
  offerStatus,
} from '../../../services/offersAPI';
import * as RouterPath from '../../../router/config';
import { RenderAllOfferCards } from '../offer/Index';
import { Modal, Placeholder } from 'react-bootstrap';
import { useCustomWeb3React } from '../../../hooks/useCustomWeb3React';
import { DispatchContext, StateContext } from '../../../context/context';
import * as appContextAction from "../../../context/actions/appContextActions";
import _ from "lodash"

const OfferDetailPages = (props: any) => {
  const [offer, setOffer]: any = useState({});
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const { slug = '' } = state;
  const [showLoading, setshowLoading] = useState(false);
  const [offersSuggested, setOffersSuggested] = useState([]);

  const [offerAvailed, setOfferAvailed] = useState(false);
  const [offerAvailing, setOfferAvailing] = useState(false);
  const [noMoreCodes, setNoMoreCodes] = useState(false);
  const [offerCode, setOfferCode] = useState('');
  const [offerSlug, setOfferSlug] = useState('')

  const [staff, setStaff] = useState('check-in staff');
  const [modelValidate, setModelValidate] = useState(false);

  const [linkToRedeem, setLinkToRedeem] = useState(false);
  const { account } = useCustomWeb3React();
  const appContextDispatch = useContext(DispatchContext);
  const { offerResponse }: any = useContext(StateContext);

  async function offerSuggestionsOnload() {
    setshowLoading(true);
    
    const offersData = _.isEmpty(offerResponse)?await getAllOffers() : offerResponse;
    if(_.isEmpty(offerResponse)){
      appContextDispatch(
        appContextAction.offerResponse(offersData)
      )
    }

    //todo: filter with type selected and show top 3
    // if none then show selct just first three
    // make sure suggested is not equal to viewving 

    setOffersSuggested([offersData[0],offersData[3],offersData[2]]);
    setshowLoading(false);
  }

  useEffect(() => {
    async function onloadCallSlugChange() {
      if (offerSlug !== '') {
        setshowLoading(true);
        const offer = await getOfferDetails(offerSlug);
        // console.log('offer: ', offer);
        //console.log(offer);

        if (offer == null) {
          navigate(RouterPath.MEMBER_OFFER);
        }

        //console.log(offer);
        setOffer(offer);

        if (offer.offersDetails.category != null) {
          if (offer.offersDetails.category == 'room') {
            setStaff('check-in staff');
          } else if (offer.offersDetails.category == 'fb') {
            setStaff('restaurant/café staff');
          } else if (offer.offersDetails.category == 'spa') {
            setStaff('spa team');
          }
        }

        // check the offer status
        let offerDetails = {
          walletAddress: account,
          offers: [
            {
              offerType: offer.offersDetails.offerType,
              offerId: offer.id,
            },
          ],
        };
        //console.log(offerDetails);
        const res = await offerStatus(offerDetails);
        //console.log(res);
        if (
          res.success &&
          res.data &&
          res.data.length>0 &&
          res.data[0].isLocked &&
          res.data[0].code != null
        ) {
          setOfferAvailed(true);
          setOfferCode(res.data[0].code);
          setModelValidate(true)
        }
        ////////

        // special case for Dms fixed offers - URLs
        if (
          offer.offersDetails.offerType == 'fixed' &&
          offer.offersDetails.hotelOfferUrl != null
        ) {
          setLinkToRedeem(true);
        }

        setshowLoading(false);
      }
    }

    onloadCallSlugChange();
  }, [offerSlug]);

  useEffect(() => {
    setOfferSlug(slug) 
    offerSuggestionsOnload()
  }, [slug]) 

  const availOfferHandler = async () => {
    setOfferAvailing(true);
    let offerDetails = {
      offerType: offer.offersDetails.offerType,
      entity:
        offer.offersDetails.entity != null
          ? offer.offersDetails.entity
          : 'null',
      offerId: offer.id,
      walletAddress: account,
      lockedTill: offer.offersDetails.canBeClaimedEvery,
    };
    //console.log(offerDetails);
    const res = await availOffer(offerDetails);
    //console.log(res);
    if (res.success && res.data.code) {
      setOfferCode(res.data.code);
      setOfferAvailed(true);
      setModelValidate(true);
    } else if (res.success && !res.data.code) {
      // no code available
      setNoMoreCodes(true);
    }
    setOfferAvailing(false);
  };

  const oncallBackSlugSuggested = (valSlug)=>{
    setOfferSlug(valSlug)
  }

  return (
    <>
      {Object.keys(offer).length > 0  ? (
        <div className="container">
          <div className="offerDetails mt-2">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="offersDetailsSlider">
                  <div className="row justify-content-md-center myNFTslider offersSlider rounded-0">
                    <div className="col text-center" data-aos="fade-right">
                      <img
                        src={
                          offer.offersDetails.image != null &&
                          offer.offersDetails.image.mediaItemUrl != null
                            ? offer.offersDetails.image.mediaItemUrl
                            : '/assets/images/offer-default.png'
                        }
                        alt="DAMAC Maison Canal Views"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-6">
                <div className="offersDetailsContent p-0-30">
                  <h3 className="mb-4">{offer.title}</h3>
                  <h5 className="mb-4 thirdColor">
                    {offer.offersDetails.subtitle}
                  </h5>
                  <p>{offer.offersDetails.description}</p>
                  {noMoreCodes && <span>Sorry No codes available</span>}
                  {offerAvailing && <Loader enable={true} />}

                  {!offerAvailed && !offerAvailing && !noMoreCodes && (
                    <div
                      className={`w-100 btnPrimary anchorTag ${offer.id}`}
                      // data-bs-toggle="modal"
                      style={{ cursor: 'pointer' }}
                      onClick={availOfferHandler}
                      // data-bs-target="#redeemModal"
                    >
                      REDEEM OFFER
                    </div>
                  )}
             

                  <Modal
                    show={modelValidate}
                    onHide={()=>setModelValidate(false)}
                    style={{color:"white"}}
                    className="modal fade"
                    backdrop="static"
                    keyboard={false}
                  >
                    <div>
                          <div className="modal-header">
                            <h2 className="modal-title">
                              REDEMPTION <span>SUCCESSFUL!</span>
                            </h2>
                            <button
                              type="button"
                              className="btn-close"
                              onClick={()=>setModelValidate(false)}
                            ></button>
                          </div>
                          <div className="modal-body mt-3">
                            <p>
                              You have successfully redeemed this offer. {
                              offer.offersDetails.category !== 'room' && `Please
                              show this code to the establishment’s attendant to
                              avail this offer.`}
                            </p>

                            { offerAvailed && !offerAvailing &&
                              offer.offersDetails.offerType == 'variable' &&
                              offer.offersDetails.category == 'room' && (
                                <>
                                  <p>
                                  Please contact our reservation departments on telephone or email, highlighted below, to check availability and make a booking. The reservation agent will need the promo code in order to validate the discount. Your promo code will only be utilized once the booking is secured. Booking availability is subject to change at any time.
                                  </p><ul>
                                  <li>Paramount Hotels: +971 4 246 6777, <a href="mailto:Reservations@paramounthotelsdubai.com">reservations@paramounthotelsdubai.com</a></li>
                                  <li>Damac Maison Hotels: +971 4 444 9111, <a href="mailto:cro@damacmaison.com">cro@damacmaison.com</a></li></ul>
                                </>
                            )}

                            <div className="w-100 btnSecondary redeemCode">
                              {offerAvailed && !offerAvailing && (
                                <span>{offerCode}</span>
                              )}
                            </div>

                            {offerAvailed &&
                              offer.offersDetails.offerType == 'fixed' &&
                              offer.offersDetails.travelclick != null && (
                                <>
                                  <p className="text-center mb-3 notify">
                                    *This code is valid for 24 hours{' '}
                                  </p>
                                  <p>
                                    <a
                                      href={offer.offersDetails.travelclick}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="anchorText"
                                    >
                                      CLICK HERE
                                    </a>{' '}
                                    to book your preferred room directly from
                                    the partner hotel’s website.
                                  </p>
                                </>
                              )}

                            {offerAvailed &&
                              offer.offersDetails.offerType == 'variable' && (
                                <p className="availed">
                                  Please present this offer code to the {staff}{' '}
                                  and they will assist you.
                                </p>
                              )}

                            <div style={{marginTop:"10px"}} className="row">
                              <div className="col-6">
                                <div
                                  onClick={() => {
                                    navigator.clipboard.writeText(offerCode);
                                  }}
                                  className="btnPrimary w-100 anchorTag"
                                >
                                  COPY CODE
                                </div>
                              </div>
                              {/* <div className="col-6">
                            <div className="btnSecondary w-100 anchorTag">
                              SEND TO EMAIL
                            </div>
                          </div> */}
                            </div>
                        </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </div>

          <div className="offerDetails mt-4">
            <div className="row">
              <div className="col-12">
                <div className="offersDetailsContent">
                  <h4 className="mb-3 whiteColor">TERMS AND CONDITIONS</h4>

                  {offer.offersDetails.termsAndConditions != null && (
                    <ul className="listing">
                      {offer.offersDetails.termsAndConditions.map(
                        ({ term }, key) => (
                          <li key={key}>{term}</li>
                        )
                      )}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3 mt-lg-5 mb-3">
            <div className="col-12">
              <h3>YOU MIGHT ALSO LIKE THESE OFFERS:</h3>
            </div>
          </div>

          <div className="row mt-3 mt-lg-4 g-3">
            <RenderAllOfferCards filteredOffer={offersSuggested} onCallback={(slugClicked)=>oncallBackSlugSuggested(slugClicked)} />
          </div>
        </div>
      ):(
        <div className='loaderDivOffer'>
        <Loader enable={showLoading}/>
        </div>
      )}
    </>
  );
};

export default OfferDetailPages;
