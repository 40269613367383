import React, { useEffect, useState, useContext } from 'react';
import Loader from '../../../../common/loader';
import {
  balanceOf,
  getTotalClaimable,
} from '../../../../contracts/functions/stakingContract';
import { useCustomWeb3React } from '../../../../hooks/useCustomWeb3React';
import {
  getUserWalletDetails,
} from '../../../../services/nodeapi';
import { toEther } from '../../../../utils/formating';
import _ from "lodash"
import { DispatchContext, StateContext } from '../../../../context/context';
import * as appContextAction from "../../../../context/actions/appContextActions";

const options: any = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const StatsCard = (props: any) => {
  const { onRedeemClick, showTitle = false } = props;
  const [nfts, setNfts] = useState([]);
  const [showLoading, setshowLoading] = useState(false);

  const today = new Date();
  const todaysDate = today.toLocaleDateString('en-US', options);

  const [balance, setBalance] = React.useState('0');
  const { account } = useCustomWeb3React();

  const [claimable, setClaimable] = React.useState('0');
  const [NFTCount, setNFTCount] = useState(0);
  const [stakedCount, setstakedCount] = useState(0);
  const { walletResponse }: any = useContext(StateContext);
  const appContextDispatch = useContext(DispatchContext);

  useEffect(() => {
    async function geStatsDetails() {
      try {
        if (account) {
          setshowLoading(true);
          const mResp = _.isEmpty(walletResponse)? await getUserWalletDetails(account, true) : walletResponse;

          if(_.isEmpty(walletResponse)){
            appContextDispatch(
              appContextAction.walletDetailResponse(mResp)
            )
          }

          const { users = [] } = mResp.data.resp.data;

          if (users.length > 0) {
            const {
              totalNftCount = 0,
              totalStakedCount = 0,
              nfts = [],
            } = users[0];
            setNFTCount(totalNftCount);
            setstakedCount(totalStakedCount);
            setNfts(nfts);
          }

          const balanceArkoda = toEther(await balanceOf(account));
          setBalance(balanceArkoda);

          setshowLoading(false);
        }
      } catch (error) {
        setshowLoading(false);
      }
    }
    geStatsDetails();
  }, [account]);

  React.useEffect(() => {
    if (nfts.length > 0) handlerClaimeable(nfts);
  }, [nfts, account]);

  const handlerClaimeable = async (d) => {
    let stakedIds = [];
    d?.forEach((nft) => {
      if (nft?.isStaked) {
        stakedIds.push(nft?.id);
      }
    });
    setClaimable(toEther(await getTotalClaimable(stakedIds, account)));
  };



  const onRedeemAll = () => {
    onRedeemClick && onRedeemClick();
  };

  return (
    <div className="col-12 text-center">
      <div className="nftCollections">
        {showTitle && (
          <div className="row">
            <div className="col-12">
              <div className="headingOpt">
                <h3 className="text-start title float-start">MY NFTs</h3>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        )}
        <div className="row pt-lg-3">
          <div className="col-12 col-lg-3 text-center bRight">
            <div className="row">
              <div className="col-6 col-lg-12">
                <h3 className="text-start text-lg-center">
                  NUMBER <span className="d-inline d-lg-block">OF NFTS</span>
                </h3>
              </div>
              <div className="col-6 col-lg-12">
                <h2 className="mt-0 mt-lg-4 mb-1 mb-lg-4 text-end text-lg-center">
                  <Loader enable={showLoading} />
                  {!showLoading && NFTCount}
                </h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 text-center bRight">
            <div className="row">
              <div className="col-6 col-lg-12">
                <h3 className="text-start text-lg-center">
                  NUMBER OF{' '}
                  <span className="d-inline d-lg-block">STAKED NFTS</span>
                </h3>
              </div>
              <div className="col-6 col-lg-12">
                <h2 className="mt-0 mt-lg-4 mb-1 mb-lg-4 text-end text-lg-center">
                  <Loader enable={showLoading} />
                  {!showLoading && stakedCount}
                </h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 text-center bRight">
            <div className="row">
              <div className="col-6 col-lg-12">
                <h3 className="text-start text-lg-center">
                  TOTAL REDEEMED{' '}
                  <span className="d-inline d-lg-block">TOKENS</span>
                </h3>
              </div>
              <div className="col-6 col-lg-12">
                <h2 className="mt-0 mt-lg-4 mb-1 mb-lg-4 text-end text-lg-center">
                  <Loader enable={showLoading} />

                  {!showLoading && parseFloat(balance).toFixed(2)}
                </h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 text-center bRight">
            <div className="row">
              <div className="col-6 col-lg-12">
                <h3 className="text-start text-lg-center">
                  TOKENS AVAILABLE{' '}
                  <span className="d-inline d-lg-block">FOR REDEMPTION</span>
                </h3>
              </div>
              <div className="col-6 col-lg-12">
                <h2 className="mt-0 mt-lg-4 mb-1 mb-lg-4 text-end text-lg-center">
                  <Loader enable={showLoading} />

                  {!showLoading && parseFloat(claimable).toFixed(2)}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-lg-3 text-center mt-3 mt-lg-4 order-last">
            <h6>* LATEST DATA AS OF {todaysDate}</h6>
          </div>
          {/* <div className="col-12 col-lg-3 offset-lg-6 text-center order-lg-last"><a target="_blank" className="btnPrimary mt-2 w-100" data-bs-toggle="modal" data-bs-target="#redeemModal">REDEEM ALL</a></div> */}

          {parseFloat(claimable) > 0 && onRedeemClick && (
            <div className="col-12 col-lg-3 offset-lg-6 text-center order-lg-last">
              <a
                target="_blank"
                className="btnPrimary mt-2"
                data-bs-toggle="modal"
                data-bs-target="#redeemModal"
                onClick={onRedeemAll}
              >
                REDEEM ALL
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatsCard;
