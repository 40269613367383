import WatchComponent from "../Watch/watchComponent";
import Slider from "react-slick";
import { useState, useEffect } from "react";
import ViewModel3DComponent from "../viewModel3D/viewModel3DComponent";
import ImageMyWatches from "../../../../../assets/images/forge/watch-icon.svg";

export default function MyWatchesComponent(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [responsiveSlide, setResponsiveSlide] = useState(3);
  const [uid3D, setUid3D] = useState("");

  const settingsStatsSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: responsiveSlide,
    slidesToScroll: responsiveSlide,
  };

  function handleStatus(uid) {
    setIsOpen(!isOpen);
    setUid3D(uid);
  }

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1000) {
        setResponsiveSlide(3);
      }
      if (window.innerWidth < 800) {
        setResponsiveSlide(2);
      }
      if (window.innerWidth < 550) {
        setResponsiveSlide(1);
      }
      if (window.innerWidth > 1000) {
        setResponsiveSlide(3);
      }
    }
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    if (window.innerWidth < 1000) {
      setResponsiveSlide(3);
    }
    if (window.innerWidth < 800) {
      setResponsiveSlide(2);
    }
    if (window.innerWidth < 550) {
      setResponsiveSlide(1);
    }
    if (window.innerWidth > 1000) {
      setResponsiveSlide(3);
    }
  }, []);

  function renderWatches() {
    const watches = [];
    for (const watch of props.myWatches) {
      watches.push(
        <div className="col-12 col-md-6 col-lg-4" key={`${watch.tokenId}`}>
          <WatchComponent
            id={watch.tokenId}
            watch={watch}
            handleStatus={handleStatus}
          />
        </div>
      );
    }
    return watches;
  }
  return (
    <>
      <div className="row mt-3 mt-lg-4">
        <div className="col-12">
          <div className="headingOpt">
            <h4 className="float-start">
              My WATCHES (<span>{props.myWatches.length}</span>)
            </h4>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
      {isOpen?(<ViewModel3DComponent handleStatus={handleStatus} uid3D={uid3D}/>):null}
      {
        props.myWatches.length > 0 ? (
          <div className="p3 roundedBox secondaryBG h-100">
            <div className="headingOpt">
              <div className="clearfix"></div>
     
              {props.myWatches.length < 4 ? (
                <div className="row mt-3 piecesToForge">{renderWatches()}</div>
              ) : (
                <Slider
                  className="row justify-content-md-center offersSlider dashboardnftSlider"
                  {...settingsStatsSlider}
                >
                 {renderWatches()}
                </Slider>
              )}
            </div>
          </div>
        ) : (<div className="row mt-3">
          <div className="col-12">
            <div className="secondaryBG p3 roundedBox text-center position-relative comingsoonHeight">
              <div className="comingsoonClaimed">
                <div className="verticallyCenter">
                  <div className="verticalContentBox">
                    <div className="row justify-content-center text-center">
                      <div className="col-12 col-md-2 mb-4 text-cente text-md-end"><img src={ImageMyWatches} alt="OFFERS" className="watchPieceIcon" /></div>
                      <div className="col-12 col-md-6 text-center text-md-start  mt-xxl-3">
                        <h2>COMING SOON</h2>
                        <p className="mb-0">ONCE WATCH PIECES ARE COMPLETED, FORGE THEM AND FORM A NEW 3D NFT WATCH.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>)
      }


    </>

  );
}
