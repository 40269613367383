// @ts-nocheck
import headerImage from "../../../../assets/images/tokenomicsImg.jpg";
import LandingLayout from "../LandingLayout/LandingLayout";
import PDF from "../../../../assets/pdf/cbwc-whitepaper.pdf";

const TokenMonics = () => {
  return (
    <div className="adminSection innerPage">
      <LandingLayout>
        <div className="innerBodySection tokenomics">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="mb-0">TOKENOMICS</h1>
                <h1>
                  <span>THE VALUE CREATING NFT</span>
                </h1>
                <div className="tokenSecImg mt-3 mt-lg-5">
                  <img src={headerImage} alt="TOKEN" className="img-fluid" />
                </div>
                <div className="secondaryBG mt-3 mt-lg-5 p3 br-20">
                  <p>
                    Arkouda isn’t just about building a
                    community of holders for its tokens. The project was
                    designed to deliver value, and utilise that value as a
                    springboard to become a truly prized NFT asset in a hugely
                    competitive metaverse.
                  </p>
                  <h3 className="mb-3">THE ARKOUDA TOKEN</h3>
                  <p className="mb-3 mb-lg-4">
                    Central to Arkouda is the Arkouda Token – a utility token paid
                    out to holders, which they can redeem to buy new NFTs, real
                    world watches and other luxury merchandise directly from the
                    club.
                  </p>
                  <p className="mb-3 mb-lg-4">
                    Arkouda has categorized the NFTs into three separate tiers. The
                    payouts vary depending on the rarity of the NFT that is
                    held.
                  </p>
                  <p className="mb-0 bFont">5% | TIER 1</p>
                  <p className="mb-3 mb-lg-4">
                    1,000 tokens awarded every 30 days to holders of super rare
                    watches
                  </p>
                  <p className="mb-0 bFont">25% | TIER 2</p>
                  <p className="mb-3 mb-lg-4">
                    300 tokens awarded every 30 days to holders of rare watches
                  </p>
                  <p className="mb-0 bFont">70% | TIER 3</p>
                  <p className="mb-3 mb-lg-4">
                    100 tokens awarded every 30 days to holders of common
                    watches
                  </p>
                  <p className="mb-3 mb-lg-4">
                    The Arkouda Token is paid out on a monthly basis, but only
                    to members who do not list their NFTs for sale. This applies
                    to minters as well as secondary buyers. This is intrinsic to
                    enable long-term value for community members and build an
                    unrivalled metaverse experience. The objective is also to
                    disincentivize short-term flippers from taking over the
                    community that Arkouda is working hard to build.
                  </p>
                  <p className="mb-3 mb-lg-4">
                    The Arkouda token will be tradable between community
                    members. All Arkouda merchandise will be priced with the
                    Arkouda Token as the primary currency.
                  </p>
                  <h3 className="mb-3">USES OF THE ARKOUDA TOKEN</h3>
                  <p className="mb-3 mb-lg-4">
                    Among other uses, the Arkouda Token is the currency for
                    holders to purchase real luxury watches directly from the
                    Arkouda website.
                  </p>
                  <p className="mb-3 mb-lg-4">
                    The watches are funded through the royalties generated by
                    the project and will be priced with Arkouda Tokens as the
                    currency of exchange. This is to ensure that the total
                    number of tokens distributed during a calendar month equate
                    in value with all the watches listed for sale during the
                    same month.
                  </p>
                  <p className="mb-3 mb-lg-4">
                    In addition, the Arkouda Token serves as the in-game
                    currency in the club’s play-to-earn game which is scheduled
                    to be launched at a later date. The game will reward members
                    with Arkouda Tokens to spend in the Arkouda marketplace and
                    redeem real world luxury prizes.
                  </p>
                  <div className="text-center mb-4">
                    <a
                      className="btnPrimary anchorTag"
                      href={PDF}
                      target="_blank"
                      rel="noreferrer"
                    >
                      DOWNLOAD WHITEPAPER
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LandingLayout>
    </div>
  );
};

export default TokenMonics;
