import bear004 from "../../../../assets/images/bear-004.png";
import bear001 from "../../../../assets/images/bear-001.png";
import bear008 from "../../../../assets/images/bear-008.png";
import bear002 from "../../../../assets/images/bear-002.png";
import bear010 from "../../../../assets/images/bear-010.png";
import bear011 from "../../../../assets/images/bear-011.png";
import bear012 from "../../../../assets/images/bear-012.png";
import Slider from "react-slick";
import { SLICK_SETTINGS_RESPONSIVE } from "../../../../utils/constants";

const TeamSection = () => {
  return (
    <section className="teamSection">
      <div>
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 text-center mt-3 mt-lg-5">
              <h1 className="mb-5" data-aos="fade-up">
                <span>THE</span> Arkouda
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
              <p className="text-center" data-aos="fade-left">
                Arkouda is the brainchild of Metaknight, founder, CEO, mastermind and the kingmaker of Planet Arkouda. Headquartered in the metaverse is this motley crew of entrepreneurs, creative heads, developers, designers, illustrators, content specialists and watch experts, fuelled by a vision to disrupt the NFT game.
              </p>
            </div>
          </div>
          <Slider
            className="row justify-content-md-center responsive mt-3 mt-lg-5  slick-initialized slick-slider slick-dotted landingSiteSlicker"
            {...SLICK_SETTINGS_RESPONSIVE}
          >
            <div className="col text-center teamPanel" data-aos="fade-right">
              <div className="teamMemberDetails">
                <div className="teamMemberPic pt-4">
                  <img src={bear004} alt="PIC" className="img-fluid" />
                </div>
                <h3 className="mt-3 mt-lg-4">Metaknight</h3>
                <h6>Founder &amp; CEO</h6>
                <p>
                  Armed with incredible foresight and matchless acumen. But it's
                  his undeniable prowess to make things happen that gives him
                  the edge in a vastly competitive Metaverse. Metaknight can
                  pull off anything from his den by pulling a few strings all
                  over the globe.
                </p>
              </div>
            </div>
            {/* <div className="col text-center teamPanel" data-aos="fade-right">
              <div className="teamMemberDetails">
                <div className="teamMemberPic pt-4">
                  <img src={bear001} alt="PIC" className="img-fluid" />
                </div>
                <h3 className="mt-3 mt-lg-4">Saeed Alayali</h3>
                <h6>CO-FOUNDER</h6>
                <p>
                  He's the undisputed champion in unarmed combat. But that's not
                  why we got him on board. It was all down to his astute
                  technical acumen, global expertise and supreme talent in
                  spotting good memes. Now he's tasked with taking our NFT game
                  to the moon.
                </p>
              </div>
            </div> */}

            {/* <div className="col text-center teamPanel" data-aos="fade-right">
              <div className="teamMemberDetails">
                <div className="teamMemberPic pt-4">
                  <img src={bear008} alt="PIC" className="img-fluid" />
                </div>
                <h3 className="mt-3 mt-lg-4">Fouad Abdel Malak</h3>
                <h6>Creative Director</h6>
                <p>
                  He's built big brands. He's destroyed stereotypes. He's bagged
                  truckloads of global awards. He's led change in the toughest
                  of places. If there's anything he hasn't done, it is to rest
                  on his laurels. Now you know where the "game-changer"
                  chromosome in our DNA comes from.
                </p>
              </div>
            </div> */}
            <div className="col text-center teamPanel" data-aos="fade-right">
              <div className="teamMemberDetails">
                <div className="teamMemberPic pt-4">
                  <img src={bear002} alt="PIC" className="img-fluid img-height"/>
                </div>
                <h3 className="mt-3 mt-lg-4">Shance Boyd</h3>
                <h6>Influencer Manager</h6>
                <p>
                  If there's one man you can count on to deliver, the money's on
                  this man. Ice cool even when he's jostling with the most
                  complex tasks, but with enough fire in his belly to get things
                  on the ground. He's the man you want in a crisis you wish you
                  never had.
                </p>
              </div>
            </div>
            <div className="col text-center teamPanel" data-aos="fade-right">
              <div className="teamMemberDetails">
                <div className="teamMemberPic pt-4">
                  <img src={bear010} alt="PIC" className="img-fluid" />
                </div>
                <h3 className="mt-3 mt-lg-4">Techwondo</h3>
                <h6>Digital Strategist</h6>
                <p>
                  Fiery, lethal, decisive. Thank heavens we're on the same team!
                  Earlier, we thought Techwondo was made for the metaverse. But
                  it's really the other way around. After steering a host of
                  big-name brands, he's now navigating our ship, like it's
                  second nature.
                </p>
              </div>
            </div>
            <div className="col text-center teamPanel" data-aos="fade-right">
              <div className="teamMemberDetails">
                <div className="teamMemberPic pt-4">
                  <img src={bear011} alt="PIC" className="img-fluid" />
                </div>
                <h3 className="mt-3 mt-lg-4">WildKarma</h3>
                <h6>Project Manager</h6>
                <p>
                  Part engineer, part digital architect, all sublime! The way
                  Wildkarma ties up all loose ends, and gets things out the door
                  in the nick of time, would put any superhero to shame. He's
                  the cog in the wheel, all greased up, always ready for action,
                  never one to back down.
                </p>
              </div>
            </div>
            <div className="col text-center teamPanel" data-aos="fade-right">
              <div className="teamMemberDetails">
                <div className="teamMemberPic pt-4">
                  <img src={bear012} alt="PIC" className="img-fluid" />
                </div>
                <h3 className="mt-3 mt-lg-4">Tuanbezel</h3>
                <h6>Product Designer</h6>
                <p>
                  There's attention to detail, and then there's attention to
                  detail as defined by Tuanbezel. Some of the world's top-notch
                  watch brands would rely on his insane skill when it comes to
                  product design. Then he decided to move on to even bigger
                  things.
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
