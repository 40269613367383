import PieceComponent from "../Piece/pieceComponent";
import ImageClaim from "../../../../../assets/images/forge/available-icon-none.svg";
import { forgeDialogue } from "../../../../../hooks/swal2";

export default function WatchForgeComponent(props) {
  const {onLoading = ()=>{}} = props;
  function handlePieceUnselected(tokenId) {
    props.handlePieceUnselected(tokenId);
  }


  function forgeNewWatch() {
    onLoading(true)
    forgeDialogue({
      dialTokenId: props.piecesToForge.find((x) => x.type.toLowerCase() === "dial").tokenId,
      strapTokenId: props.piecesToForge.find((x) => x.type.toLowerCase() === "strap").tokenId,
      caseTokenId: props.piecesToForge.find((x) => x.type.toLowerCase() === "case").tokenId,
      account: props.account
    }, () => {
      onLoading(false)
      props.handleNewWatchForge();
    });
  }

  function renderPieces() {
    const pieces = [];
    for (const piece of props.piecesToForge) {
      pieces.push(
        <div className="col-12 col-md-4 mb-3 col-lg-4" key={`${piece.tokenId}-forge`}>
          <PieceComponent
            code={piece.code}
            type={piece.type}
            name={piece.name}
            image={piece.image}
            tokenId={piece.tokenId}
            isSelected={true}
            isForging={true}
            onRemove={handlePieceUnselected}
          />
        </div>
      );
    }

    return pieces;
  }

  return (
    <>
          <div className="row ">
        <div className="col-12">
          <div className="headingOpt">
            <h4 className="float-start">
              FORGE A WATCH
            </h4>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
      <div className="p3 roundedBox secondaryBG  h-100">

      {props.piecesToForge.length > 0 ? (
        <div className="row mt-3 piecesToForge">
          {renderPieces()}
          {props.piecesToForge.length === 3 ? (
            <div className="mt-3 mb-8">
              <button className="btnPrimary mb-3 w-100 float-end" onClick={() => forgeNewWatch()}>
                Forge
              </button>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="row mt-3">
        <div className="col-12">
          <div className="secondaryBG p3 roundedBox borderForge text-center position-relative comingsoonHeight">
            <div className="comingsoonClaimed">
              <div className="verticallyCenter">
                <div className="verticalContentBox">
                <div className="row justify-content-center text-center">
                        <div className="col-12 col-md-2 text-center mt-4 text-md-end"><img src={ImageClaim} alt="OFFERS" className="watchPieceIcon" /></div>
                        <div className="col-12 col-md-6 text-center text-md-start  mt-xxl-3">
                    <h2>FORGE A WATCH</h2>
                    <p className="mb-0">SELECT FROM THE COMPATIBLE PIECE COMBINATIONS ABOVE TO FORGE A WATCH.</p>
                  </div>
                      </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
    </div>
    </>
    
  );
}
