import { gql } from '@apollo/client';
import client from '../../../../apollo-client';

export async function getNewsDetails(slug) {
  // console.log('pid: ', slug);

  const { data } = await client.query({
    query: gql`
      query NewsDetailQuery($pid: String!, $lid: Int!) {
        heroareas {
          nodes {
            heroAreaDetails {
              title
              subTitle
              socialMediaLinks {
                link
                icon {
                  altText
                  mediaItemUrl
                }
              }
              websiteLogo {
                altText
                mediaItemUrl
              }
              burgerMenu {
                link
                label
                externalUrl
                homePageSection
              }
            }
          }
        }
        newsBy(uri: $pid) {
          title
          newsDetails {
            description
            image {
              altText
              mediaItemUrl
            }
            moreDetails
            gallery {
              image {
                mediaItemUrl
              }
            }
            description2 {
              text
              image {
                mediaItemUrl
              }
            }
            description3
          }
          seoMetaTags {
            metaTitle
            metaDescription
          }
        }
        listingBy(listingId: $lid) {
          listingPageDetails {
            title
            subtitle
          }
        }
      }
    `,
    variables: { pid: slug, lid: 205 },
  });

  if (!data || !data.heroareas || !data.newsBy) {
    return {
      notFound: true,
    };
  }

  console.log('data: ', data);

  // Pass data to the page via props
  return data;
}
