export const CHAIN_ID: any = parseInt(process.env.REACT_APP_WALLET_CHAINID);

export const CONTRACT_ADDRESSES =
  parseInt(process.env.REACT_APP_WALLET_CHAINID) === 1
    ? {
        crypto_bear: "0x22c594c42fcd0b9dfde27aa8976a510c9d044356",
        staking: "0xaB84Ad4586EaE34B1849d790B51eF4Cd0CA58e65",
        pieces: "0x8F3B4fc0c552131e4AF4f729712dF516C46D0dFA",
        watch: "0x663AfAbd4E6EeBC04f44708C52F9aF835857d711",
      }
    : {
        crypto_bear: "0xaf2042E45364044453E4a31a12bCE8bC2b2fF4e6",
        staking: "0x3E330A801104E86E1a837e2423725472A6005bDA",
        pieces: "0xa3bc8e00E0f98c00dbeB3c64495EF1c78f340Af7",
        watch: "0x2F10d2d06850bD098F46C46e3d76742b42ddD783",
      };

export const INFURA_KEY = "a78b7070f1f946c28a73488422ad57a4";

export const CONTRACT_ARKODA_ADDRESS_PROD =
  "0xaB84Ad4586EaE34B1849d790B51eF4Cd0CA58e65";
export const CONTRACT_NFT_BEAR_ADDRESS_PROD =
  "0x22c594c42fcd0b9dfde27aa8976a510c9d044356";

export const OPENSEA_LINK =
  "https://opensea.io/collection/thecryptobearwatchclub";
export const DISCORD_LINK = "https://discord.gg/EuT4mnkAbM";
export const TWITTER_LINK = "https://twitter.com/ArkoudaNFT";
