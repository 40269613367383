import { useNavigate } from 'react-router-dom';
import dashBoardScreen from '../../../../assets/images/dashboardScreen.png';
import { useCustomWeb3React } from '../../../../hooks/useCustomWeb3React';
import * as RouterPath from '../../../../router/config';

const DashboardSection = () => {
  const { account } = useCustomWeb3React();
  const navigate = useNavigate();

  const GotoMyDashboard = () => {
    if (account) {
      navigate(RouterPath.MEMBER_DASHBOARD);
    } else {
      navigate(RouterPath.CONNECT);
    }
  };

  return (
    <section id="dashboardSection">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-5 text-lg-start col-xxl-5 pe-lg-0 contentBox">
            <h1 data-aos="fade-right">
              <span>OUR</span>
              <br />
              DASHBOARD
            </h1>
            <div className="d-none d-lg-block">
              <h3 className="mt-5" data-aos="fade-right">
                STAKE YOUR NFT<span className="text-lowercase">s</span>
              </h3>
              <p data-aos="fade-right">
                As an Arkouda member, you can earn Arkouda Tokens when you log into our member portal. – connect your wallet and stake your NFTs. The number of tokens redeemed depends on the tier of NFT you hold.
              </p>

              <h3 className="mt-3" data-aos="fade-right">
                GENERATE ARKOUDA TOKENS
              </h3>
              <p data-aos="fade-right">
                The Arkouda Tokens you generate can be used to redeem real world
                watches and other merchandise directly from the club. The tokens
                also serve as the in-game currency in the club's play-to-earn
                game which is soon to be launched.
              </p>

              <div className="my-2">
                <div className="col-12">
                  <div
                    onClick={GotoMyDashboard}
                    className="btnPrimary mt-2"
                    data-aos="fade-right"
                  >
                    GO TO MY DASHBOARD
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 highlighterBg">
            <div className="row g-3">
              <div className="col">
                <div className="text-center" data-aos="fade-up">
                  <img
                    src={dashBoardScreen}
                    alt="Dashboard"
                    className="dashboardScrn"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-block d-lg-none" data-aos="fade-down">
            <h3 className="mt-5">
              STAKE YOUR NFT<span className="text-lowercase">s</span>
            </h3>
            <p>
              As an Arkouda member, you can earn Arkouda Tokens when you log into our member portal. – connect your wallet and stake your NFTs. The number of tokens redeemed depends on the tier of NFT you hold.
            </p>

            <h3 className="mt-3">GENERATE ARKOUDA TOKENS</h3>
            <p>
              The Arkouda Tokens you generate can be redeemed to buy new NFTs,
              real world watches and other merchandise directly from the club.
              The tokens also serve as the in-game currency in the club's
              play-to-earn game which is soon to be launched.
            </p>

            <div className="my-2">
              <div className="col-12">
                <div
                  onClick={GotoMyDashboard}
                  className="btnPrimary mt-2"
                  data-aos="fade-right"
                >
                  GO TO MY DASHBOARD
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardSection;
