import closeIcon from '../../../../assets/images/close-round-icon-white.svg';

interface NotificationCardProps {
  id:string;
  title: string;
  subtitle: string;
  onClose: any;
}

const NotificationCard = (props: NotificationCardProps) => {
  const { id ='', title = '', subtitle = '', onClose = () => {} } = props;

  return (
    <div className="alertText">
      <h5>{title}</h5>
      <p>{subtitle}</p>
      <div className="closeNotification">
        <img
          src={closeIcon}
          style={{cursor:"pointer"}}
          onClick={()=>onClose(id)}
          alt="CLOSE"
          className="img-fluid"
        />
      </div>
    </div>
  );
};

export default NotificationCard;
