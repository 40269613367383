import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import OpenSea from '../../../../assets/images/OpenSea.svg';
import CdnImageNft from '../../../../common/CdnImageNft';
import Loader from '../../../../common/loader';
import { getNFTMetaData } from '../../../../services/nodeapi';
import PogressLineBar from './PogressLineBar';

interface NftDetailsModelProps {
  detailModelView: boolean;
  setDetailModelView: any;

  openStats: any;
  id: string;
  tier: string;
}

const NftDetailsModel = (props: NftDetailsModelProps) => {
  const {
    detailModelView,
    setDetailModelView = () => { },
    openStats,
    id,
    tier,
  } = props;
  const [showNones, setShowNones] = useState(false);
  const [metData, setMetData]: any = useState();
  const [nftLoading, setNftLoading] = useState(false);

  const openseaLink =
    'https://opensea.io/assets/ethereum/0x22c594c42fcd0b9dfde27aa8976a510c9d044356/' +
    id;

  const onShownonseClick = () => {
    setShowNones(!showNones);
  };

  const sumOfAttributeType = (types = {}): any => {
    return Object.values(types).reduce(
      (partialSum: number, a: number) => partialSum + a,
      0
    );
  };


  useEffect(() => {
    if (!metData) {
      setNftLoading(true);
      getNFTMetaData(id).then((response: any) => {
        setMetData(response.data.res);
        setNftLoading(false);
      });
    }
  }, []);

  return (
    <Modal
      show={detailModelView}
      onHide={() => setDetailModelView(false)}
      backdrop="static"
      className="modal fade"
      style={{ color: 'white' }}
      keyboard={false}
    >
      <div>
        <div className="modal-header">
          <h2 className="modal-title">MY NFTs</h2>
          <button
            type="button"
            className="btn-close"
            onClick={() => setDetailModelView(false)}
          ></button>
        </div>
        <div className="modal-body mt-3">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="nftViewPopup">
                <CdnImageNft id={id} className="img-fluid" />

                <h3 className="mt-3">Arkouda #{id}</h3>
                <h4 className="text-capitalize whiteColor opacity-50 mb-3">
                  Tier {tier}
                </h4>
                <a
                  href={openseaLink}
                  target="_blank"
                  rel="noreferrer"
                  className="float-end text-decoration-none btnPrimary p-2 w-100"
                >
                  <img src={OpenSea} alt="OpenSea" />
                  VIEW ON OPENSEA
                </a>
              </div>
            </div>

            <div className="col-12 col-lg-6 mt-4 mt-lg-0">
              <div className="myNFTprogress">
                {nftLoading && (
                  <div className="loadCenter">
                    <Loader enable={true} />
                  </div>
                )}
                {metData?.attributes?.map((eachAtr) => {
                  const valRes = parseInt(
                    openStats?.traits[eachAtr.trait_type][
                    eachAtr.value.toLowerCase()
                    ]
                  );
                  return (
                    valRes &&
                    (valRes > 0 || showNones) && (
                      <PogressLineBar
                        val={valRes}
                        traitType={eachAtr.trait_type}
                        traitValue={eachAtr.value}
                        maxTraitSum={sumOfAttributeType(
                          openStats?.traits[eachAtr.trait_type]
                        )}
                      />
                    )
                  );
                })}
              </div>
            </div>
          </div>
          <div className="row mt-3 mt-lg-5">
            <div className="col-12 col-lg-6">
              {/* <div onClick={onShownonseClick} className="w-auto d-inline selectStake float-start">
                  <input type="checkbox" id="chk01" name="chk01" checked={showNones} />
                  <label></label>
                </div>
                <div className="showNoneblock float-start">SHOW NONES</div> */}
              <div className="clearfix"></div>
            </div>
            <div className="col-12 col-lg-6 mt-3 mt-lg-0">
              <div className="row">
                {/* <div className="col-6">
                    <div className="anchorTag w-100 btnSecondary p-2 bNone">
                      Previous
                    </div>
                  </div> */}
                <div
                  onClick={() => setDetailModelView(false)}
                  className="col-12"
                >
                  <div className="anchorTag w-100 btnPrimary p-2">Done</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NftDetailsModel;
