import React, { useRef, useEffect, useState, useContext } from 'react';
import HeaderText from '../components/HeaderText/HeaderText';
import StatsCard from '../components/StatsCard/StatsCard';
import NftCard from '../components/NftCard/NftCard';

import Swal from 'sweetalert2';
import { getUserWalletDetails } from '../../../services/nodeapi';
import {
  claimRewards,
  stakeCBWC,
  unStakeCBWC,
} from '../../../contracts/functions/stakingContract';
import tickMarkImg from '../../../assets/images/tick-mark-xs.svg';
import offerIconImage from '../../../assets/images/navigationIcons/offers-icon-white.svg';
import { useCustomWeb3React } from '../../../hooks/useCustomWeb3React';
import { DispatchContext } from '../../../context/context';
import * as appContextAction from "../../../context/actions/appContextActions";
import Loader from '../../../common/loader';
import _ from "lodash"

const Staking = (props: any) => {
  const [stakeAddressList, setStakeAddressList] = useState({});
  const [stackedOnlyChecked, setStackedOnlyChecked] = useState(false);
  const [nftFiltered, setNftFiltered] = useState([]);
  const [sliceValue, setSliceValue] = useState(8);
  const [showLoading, setshowLoading] = useState(false);
  const appContextDispatch = useContext(DispatchContext);

  const [nfts, setNfts] = useState([]);
  const { account } = useCustomWeb3React();

  async function fetchData() {
    try {
      if (account) {
        setshowLoading(true);
        const mResp = await getUserWalletDetails(account, true);
        appContextDispatch(appContextAction.walletDetailResponse(mResp));

        const { users = [] } = mResp.data.resp.data;

        if (users.length > 0) {
          const { nfts = [] } = users[0];
          setNfts(nfts);
          setNftFiltered(nfts);
        }

        setshowLoading(false);
      }
    } catch (error) {
      setshowLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [account]);

  const toggleInStakeList = async (tokenId) => {
    let list = { ...stakeAddressList };
    if (list[tokenId]) {
      delete list[tokenId];
    } else {
      list[tokenId] = true;
    }
    setStakeAddressList(list);
  };

  const handlerUnstake = async () => {
    const i = Object.keys(stakeAddressList).length;
    if (i === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Please select items to unstake',
      });
    } else {
      setshowLoading(true);
      await unStakeCBWC(Object.keys(stakeAddressList), account);
      setshowLoading(false);

      //refetch()
      await fetchData();
    }
  };

  const handlerStake = async () => {
    const i = Object.keys(stakeAddressList).length;
    // const isUnstake =
    if (i === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Please select items to stake',
      });
    } else {
      setshowLoading(true);
      await stakeCBWC(Object.keys(stakeAddressList), account);
      setshowLoading(false);

      // refetch();
      await fetchData();
    }
  };

  const handlerClaimRewards = async () => {
    const i = Object.keys(stakeAddressList).length;
    if (i === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Please select items to claim',
      });
    } else {
      setshowLoading(true);
      await claimRewards(Object.keys(stakeAddressList), account);
      setshowLoading(false);

      //refetch()
      await fetchData();
    }
  };

  const handlerUnstakeSingle = async (tokenId) => {
    setshowLoading(true);
    await unStakeCBWC([tokenId], account);
    setshowLoading(false);
    await fetchData();
  };

  const handlerStakeSingle = async (tokenId) => {
    setshowLoading(true);
    await stakeCBWC([tokenId], account);
    setshowLoading(false);
    await fetchData();
  };

  const stackToggelClick = (stackVal: boolean) => {
    setStackedOnlyChecked(stackVal);

    if (stackVal) {
      const stakedNft = nfts ? nfts?.filter((nft) => nft?.isStaked) : [];
      setNftFiltered(stakedNft);
    } else {
      setNftFiltered(nfts);
    }
  };

  const onLoadmore = () => {
    const sliceValueNew = sliceValue + 12;
    if (sliceValueNew >= nftFiltered.length) {
      setSliceValue(nftFiltered.length);
    } else {
      setSliceValue(sliceValueNew);
    }
  };

  return (
    <div className="stakingSection">
      <HeaderText
        title="STAKING"
        subtitle="STAKE/ UNSTAKE YOUR NFT CollECTIONS"
      />

      <StatsCard onRedeemClick={handlerClaimRewards} />

      <div className="container myNFTs">
        <div className="row mt-3 mt-lg-5">
          <div className="col-12 col-lg-3">
            <h3>MY NFTS</h3>
          </div>
          <div className="col-12 col-lg-9 text-start text-lg-end filterOptions float-start float-md-none">
            <div className="stakedToggle w-50 d-inline-block d-md-inline text-start xs50">
              <span
                onClick={() => stackToggelClick(!stackedOnlyChecked)}
                className="radioToggle"
              >
                <input type="radio" name="toggle" value="off" />
                <input type="radio" name="toggle" value="on" />
                <img src={tickMarkImg} className="tickIconToggle" alt="Tick" />
                <span className="switch"></span>
              </span>
              <span>STAKED ONLY</span>
            </div>
            {/* <div className="w-50 d-inline-block d-md-inline text-end float-end float-md-none xs50">
                  <a href="#" className="thirdBG">
                    DESELECT ALL
                  </a>
                </div> */}
            <div className="w-50 d-inline-block d-md-inline text-start float-start float-md-none xs50">
              <a
                style={{ cursor: 'pointer' }}
                onClick={handlerStake}
                className="primaryBG"
              >
                STAKE ALL
              </a>
            </div>
            <div className="w-50 d-inline-block d-md-inline text-end float-end float-md-none xs50">
              <a
                style={{ cursor: 'pointer' }}
                onClick={handlerUnstake}
                className="whiteBG"
              >
                UNSTAKE ALL
              </a>
            </div>
            <div className="d-clearfix"></div>
          </div>
        </div>
        <div className="row mt-3 mt-lg-4 g-3">
          {nftFiltered.length > 0 && !showLoading ? (
            nftFiltered
              .slice(0, sliceValue)
              .map((each) => (
                <NftCard
                  key={'nftFiltered-items-' + each.id}
                  data={each}
                  isStackedCheck={each.isStaked}
                  isChecked={stakeAddressList[each.id]}
                  onCheckToggel={toggleInStakeList}
                  onStakeClick={handlerStakeSingle}
                  onUnstakeClick={handlerUnstakeSingle}
                />
              ))
          ) : (
            <>
              {!showLoading && (
                <div className="row mt-3 mt-lg-4 g-3">
                  <div className="secondaryBG noOffer">
                    <div className="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2">
                      <div className="row justify-content-md-center text-center">
                        <div className="col-12 col-md-auto mb-3 mb-md-0">
                          <img
                            src={offerIconImage}
                            alt="OFFERS"
                            className="noOfferIcon"
                          />
                        </div>
                        <div className="col-12 col-md-auto text-md-start">
                          <h3>
                            {stackedOnlyChecked
                              ? 'THERE ARE NO STAKED NFTs.'
                              : 'THERE ARE NO CBWC NFTs  IN YOUR NAME.'}
                          </h3>
                          <p>
                            {stackedOnlyChecked
                              ? 'YOU CAN STAKE BY SELECTING NFTS.'
                              : 'YOU CAN PURCHASE IT FROM OPENSEA.'}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {nftFiltered.length > 0 &&
            !showLoading &&
            sliceValue < nftFiltered.length && (
              <div
                className="loadMore mt-3 mt-lg-5 text-center"
                onClick={onLoadmore}
              >
                <a>Load more</a>
              </div>
            )}
        </div>
      </div>
      <div className="loadmore">
        <Loader enable={showLoading} />
      </div>
    </div>
  );
};

export default Staking;
