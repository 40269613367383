import * as RouterPath from '../../../../router/config';
import { useNavigate } from 'react-router-dom';

interface OffercardProps {
  title: string;
  subtitle: string;
  description: string;
  imgSrc: string;
  slug: string;
  onCallback:any;
}

const Offercard = (props: OffercardProps) => {
  const navigate = useNavigate();

  const {
    title = '',
    subtitle = '',
    description = '',
    imgSrc = '',
    slug = '',
    onCallback = '',
  } = props;
  // console.log('slug: ', slug);

  const onRedeemClick = () => {
    if (onCallback !== '') {
      onCallback(slug);
    } else {
      navigate(RouterPath.MEMBER_OFFER_DETAIL, { state: { slug: slug } });
    }
  };
  return (
    <div className="col-12 col-md-6 col-lg-4">
      <div className="secondaryBG">
        <a className="text-decoration-none">
          <div>
            <img
              key={'offercard image'}
              className="img-fluid"
              src={imgSrc}
              alt="offercard"
            />
          </div>
          <div className="offersBlock">
            <h3>{title}</h3>
            <h4>{subtitle}</h4>
            <p>{description}</p>
            <div className="mt-3">
              <div onClick={onRedeemClick} className="w-100 btnPrimary">
                REDEEM OFFER
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Offercard;
