import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { SIDEBAR_MEMBER_PORTAL_OPTIONS } from '../../../../utils/constants';

const Sidebar = (props: any) => {
  const { showBtn } = props;
  // const [selectedOption, setSelectedOption]: any = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const onSideBarClick = (event, details: any) => {
    navigate(details.route);
  };

  const classNav =
    showBtn && !isMobile
      ? 'stickyNavigation d-none d-xl-block open'
      : 'stickyNavigation d-none d-xl-block';

  const classNavigator =
    showBtn && !isMobile ? 'navigationSticky open' : 'navigationSticky';

  return (
    <>
      <div className={classNav}></div>
      <div className={classNavigator}>
        <div className="container">
          <div className="row">
            {SIDEBAR_MEMBER_PORTAL_OPTIONS.map((eachSide) => {
              return (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => onSideBarClick(e, eachSide)}
                  key={'eachSide ' + eachSide.name}
                  className="col"
                >
                  <a
                    className={
                      eachSide?.activeON?.includes(location.pathname)
                        ? 'active'
                        : ''
                    }
                  >
                    <div className="stickyNavIcon">
                      <img src={eachSide.imgSrc} alt={eachSide.name} />
                    </div>
                    <div className="d-block d-md-inline-block ">
                      {' '}
                      {eachSide.name}{' '}
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
// className="active"
{
  /* <div class="col"><a href="dashboard.html" class="active"><n><img src="../../../../assets/images/navigationIcons/dashboard-icon-white.svg" alt="DASHBOARD" /></n><l>DASHBOARD</l></a></div> */
}
