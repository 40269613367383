import { forgeWatch } from "../nodeapi";
import { forge } from "../../contracts/functions/watchContract";

interface ForgeWatchOptions {
  dialTokenId: number;
  strapTokenId: number;
  caseTokenId: number;
  account: string;
}
const ForgeWatchService = async (options: ForgeWatchOptions) => {
  const pieces = [
    options.dialTokenId,
    options.strapTokenId,
    options.caseTokenId
  ];
  const response = await forgeWatch(pieces);
  if (response.status !== 200) {
    if (response.data.error) {
      throw new Error(response.data.message);
    } else {
      throw new Error("Unknown error");
    }
  }
  return await forge(response.data.transaction, options.account);
};

export default ForgeWatchService;
