import { useEffect, useState,useContext } from 'react';
import Loader from '../../../common/loader';
import { getAllOffers } from '../../../services/offersAPI';
import Offercard from '../components/Offercard/Offercard';
import OfferImagebanner from '../components/OfferImagebanner/OfferImagebanner';
import offerIconImage from "../../../assets/images/navigationIcons/offers-icon-white.svg";
import { DispatchContext, StateContext } from '../../../context/context';
import * as appContextAction from "../../../context/actions/appContextActions";
import _ from "lodash"

const OFFER_CATEGORY_TYPES = [
  { name: 'All offers', key: 'All' },
  { name: 'HOTEL ROOMS', key: 'room' },
  { name: 'FOOD AND BEVERAGES', key: 'fb' },
  { name: 'SPA AND WELLNESS', key: 'spa' },
];

export const RenderAllOfferCards = (props) => {
  const { filteredOffer = [], onCallback='' } = props;

  return (
    <>
      {filteredOffer.map((eachOffer) => {
        return (
          <Offercard
            title={eachOffer.title}
            subtitle={eachOffer.offersDetails.subtitle}
            description={
              eachOffer.offersDetails.summery != null &&
              eachOffer.offersDetails.summery
            }
            imgSrc={
              eachOffer.offersDetails.image != null &&
              eachOffer.offersDetails.image.mediaItemUrl != null
                ? eachOffer.offersDetails.image.mediaItemUrl
                : '/assets/images/offer-default.png'
            }
            slug={eachOffer.slug}
            onCallback={onCallback}
          />
        );
      })}
    </>
  );
};

const OfferPage = (props: any) => {
  const [offers, setOffers] = useState([]);
  console.log('offers: ', offers);
  const [loading, setLoading] = useState(false);
  const [filteredOffer, setFilteredOffer] = useState([]);
  const [selectedCat, setSelectedCat] = useState('All');
  const {  offerResponse }: any = useContext(StateContext);
  const appContextDispatch = useContext(DispatchContext);

  useEffect(() => {
    async function offerOnload() {
      setLoading(true);
      // const offersData = await getAllOffers();
      const offersData = _.isEmpty(offerResponse)?await getAllOffers() : offerResponse;
      if(_.isEmpty(offerResponse)){
        appContextDispatch(
          appContextAction.offerResponse(offersData)
        )
      }

      setOffers(offersData);

      setLoading(false);
    }

    offerOnload();
  }, []);

  useEffect(() => {
    if (selectedCat === 'All') {
      setFilteredOffer(offers);
    } else {
      const catFiltered = offers.filter(
        (offer) => offer.offersDetails.category === selectedCat
      );
      setFilteredOffer(catFiltered);
    }
  }, [selectedCat, offers]);

  const onFilterChange = (event) => {
    console.log('event: ', event);
    setSelectedCat(event.target.value);
  };

  return (
    <div className="stakingSection mb-5">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 titleBox">
            <h1 data-aos="fade-right">OFFERS</h1>
            <h2 data-aos="fade-right">
              <span>LUXURY leisure &amp; hospitality perks </span>
            </h2>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mt-3 mt-lg-4">
          <div className="col-12">
            <h3 className="title3">FEATURED OFFERS</h3>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-12">
            <OfferImagebanner />
          </div>
        </div>
        <div className="row mt-3 mt-lg-5">
          <div className="col-12 col-lg-3">
            <h3 className="title3">ALL OFFERS</h3>
          </div>
          <div className="col-12 col-lg-9 text-start text-lg-end filterOptions float-start float-md-none">
            <div className="sortFilter">
              <label>Sort by:</label>
              <select
                onChange={onFilterChange}
                name="select sort cat"
                className="selectpicker"
              >
                {OFFER_CATEGORY_TYPES.map((eachCatType) => (
                  <option key={eachCatType.key} value={eachCatType.key}>
                    {eachCatType.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="row mt-3 mt-lg-4 g-3">
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '80px',
            }}
          >
            <Loader enable={loading} />
          </div>

          {!loading && <RenderAllOfferCards filteredOffer={filteredOffer} />}

          {!loading && filteredOffer.length === 0 && (
            <div className="row mt-3 mt-lg-4 g-3">
              <div className="secondaryBG noOffer">
                <div className="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2">
                  <div className="row justify-content-md-center text-center">
                    <div className="col-12 col-md-auto mb-3 mb-md-0">
                      <img
                        src={offerIconImage}
                        alt="OFFERS"
                        className="noOfferIcon"
                      />
                    </div>
                    <div className="col-12 col-md-auto text-md-start">
                      <h3>THERE ARE NO AVAILABLE OFFERS YET FOR YOU.</h3>
                      <p>WATCH OUT FOR MORE OFFERS FOR YOU SOON.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="clearfix"></div>
          {/* <div className="loadMore mt-3 mt-lg-5 text-center">
            <a href="#">Load more</a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default OfferPage;
