import { gql } from "@apollo/client";
import client from "./apollo-client";
import axios from "axios";

export async function getAllOffers() {
    const { data } = await client.query({
        query: gql`
        query q {
            offers(last:100) {
                nodes {
                    title
                    slug
                    offersDetails {
                        subtitle
                        image {
                            mediaItemUrl
                        }
                        offerType
                        description
                        howItWorks
                        termsAndConditions {
                            term
                        }
                        entity
                        canBeClaimedEvery
                        order
                        category
                        summery
                    }
                }
            }
        }
      `});
    return data.offers.nodes;
}
export async function getOfferDetails(uri) {
    const { data } = await client.query({
        query: gql`
            query q($uri:String!) {
                offerBy(uri: $uri) {
                    id
                    title
                    slug
                    offersDetails {
                        subtitle
                        image {
                            mediaItemUrl
                        }
                        offerType
                        promocode
                        description
                        howItWorks
                        termsAndConditions {
                            term
                        }
                        entity
                        canBeClaimedEvery
                        order
                        category
                        travelclick
                        summery
                        hotelOfferUrl
                    }
                }
            }
        `, variables: { uri: uri }
    });
    return data.offerBy;
}
export async function availOffer(offerDetails) {
    let res:any = {};
    await axios.post(`${process.env.REACT_APP_CBWC_WEB_API_URL}/offers/availOffer`, offerDetails)
        .then(function (response) {
            res = response;
        })
        .catch(function (error) {
            console.log(error);
        });
    return res?.data;
}

export async function offerStatus(offerDetails) {
    let res:any = {};
    await axios.post(`${process.env.REACT_APP_CBWC_WEB_API_URL}/offers/checkOffer`, offerDetails)
        .then(function (response) {
            res = response;
        })
        .catch(function (error) {
            console.log(error);
        });
    return res.data;
}