import PieceComponent from "../Piece/pieceComponent";
import Slider from "react-slick";
import * as RouterPath from "../../../../../router/config";
import { useNavigate } from "react-router-dom";
import ImageMyPieces from "../../../../../assets/images/forge/watch-pieces-icon.svg";


import { useEffect, useState } from "react";

export default function MyPiecesComponent(props) {
  const DEFAULT_SLICE_VALUE = 4;
  const {myPieces} = props;
  const [responsiveSlide, setResponsiveSlide] = useState(4);
  const [sliceValue, setSliceValue] = useState(DEFAULT_SLICE_VALUE);
  const navigate = useNavigate();

  const settingsStatsSlider = {
    dots: false,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: responsiveSlide,
    slidesToScroll: responsiveSlide
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1000) {
        setResponsiveSlide(3);
      }
      if (window.innerWidth < 800) {
        setResponsiveSlide(2);
      }
      if (window.innerWidth < 550) {
        setResponsiveSlide(1);
      }
      if (window.innerWidth > 1000) {
        setResponsiveSlide(4);
      }
    }
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    if (window.innerWidth < 1000) {
      setResponsiveSlide(3);
    }
    if (window.innerWidth < 800) {
      setResponsiveSlide(2);
    }
    if (window.innerWidth < 550) {
      setResponsiveSlide(1);
    }
    if (window.innerWidth > 1000) {
      setResponsiveSlide(4);
    }
  }, []);

  function handleAddPiece(tokenId) {
    props.handlePieceSelected(tokenId);
  }

  function validateCombination(piece) {
    if (Array.isArray(props.piecesToForge) && props.piecesToForge.length === 0) {
      return true;
    }

    if (Array.isArray(props.combinations) && props.combinations.length > 0) {
      for (const combination of props.combinations) {
        if (props.piecesToForge.find((x) => x.type === piece.type)) return false;
        switch (piece.type.toLowerCase()) {
          case "dial":
            if (combination.dialCode === piece.code) return true;
            break;
          case "strap":
            if (combination.strapCode === piece.code)
              return true;
            break;
          case "case":
            if (combination.caseCode === piece.code)
              return true;
            break;
        }
      }
      return false;
    }
    return true;
  }

  function handleNavToForge() {
    navigate(RouterPath.MEMBER_FORGE);
  }

  function renderPieces() {
    const pieces = [];
    for (const [i, piece] of myPieces.entries()) {

      if (i < 4) {
        pieces.push(
          <div className="col-12 col-md-6 col-lg-3" key={`${piece.tokenId}`}>
            <PieceComponent
              code={piece.code}
              name={piece.name}
              type={piece.type}
              image={piece.image}
              tokenId={piece.tokenId}
              isValidForCombination={validateCombination(piece)}
              onAdd={handleAddPiece}
              countPiecesToForge={Array.isArray(props.piecesToForge) ? props.piecesToForge.length : []}
              isForging={props.isForging}
              isSelected={false}
            />
          </div>
        );
      } else {
        pieces.push(
          <div className={`col-12 col-md-6 col-lg-3`} key={`${piece.tokenId}`}>
            <PieceComponent
              code={piece.code}
              name={piece.name}
              type={piece.type}
              image={piece.image}
              tokenId={piece.tokenId}
              isValidForCombination={validateCombination(piece)}
              onAdd={handleAddPiece}
              countPiecesToForge={Array.isArray(props.piecesToForge) ? props.piecesToForge.length : []}
              isForging={props.isForging}
              isSelected={false}
            />
          </div>
        );
      }

    }
    return pieces.slice(0,sliceValue);
  }

  const onLoadMore = () => {
    const sliceValueNew = sliceValue + 8;

    if (sliceValueNew >= myPieces.length) {
      setSliceValue(myPieces.length);
    } else {
      setSliceValue(sliceValueNew);
    }
  };

  const onLoadLess = () => {
      setSliceValue(DEFAULT_SLICE_VALUE);
  }

  return (
    <>
      <div className="row mt-3 mt-lg-4">
        <div className="col-12">
          <div className="headingOpt">
            <h4 className="float-start">
              My WATCH PIECES (<span>{myPieces.length}</span>)
            </h4>

            {props.isForging === false ? (
              <div
                onClick={handleNavToForge}
                className="float-end thirdBG text-decoration-none viewallCustom "
              >
                Forge Watch
              </div>
            ) : null}
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
      {props.myPieces.length > 0 ? (
        <div className="p3 roundedBox secondaryBG h-100">
          <div className="headingOpt">
            <div className="row mt-3 piecesToForge">{renderPieces()}</div>
              {sliceValue < props.myPieces.length ? (
                <div className="loadMore mt-3 mt-lg-5 text-center" onClick={onLoadMore}><a>Load more</a></div>
              ) : (
              <div className="loadMore mt-3 mt-lg-5 text-center" onClick={onLoadLess}><a>Load less</a></div>
              )}
          </div>
        </div>
      ) : (
        <>
          <div className="row mt-3">
            <div className="col-12">
              <div className="secondaryBG p3 roundedBox text-center position-relative comingsoonHeight">
                <div className="comingsoonClaimed">
                  <div className="verticallyCenter">
                    <div className="verticalContentBox">
                      <div className="row justify-content-center text-center">
                        <div className="col-12 col-md-2 text-center mb-4 text-md-end">
                          <img
                            src={ImageMyPieces}
                            alt="OFFERS"
                            className="watchPieceIcon"
                          />
                        </div>
                        <div className="col-12 col-md-6 text-center text-md-start  mt-xxl-3">
                          <h2>MY WATCH PIECES</h2>
                          <p className="mb-0">
                            COLLECTED WATCH PIECES ARE DISPLAYED HERE. COLLECT
                            ALL PAIR COMBINATIONS AND MINT YOUR OWN WATCH.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
