//@ts-nocheck
import { FC, SyntheticEvent, useState } from "react";
import defulatBear from "../../assets/bear-placeholder.jpeg";
interface CdnImageNftProps {
  id?: string;
  className?: string;
  defaultImage?: any;
}

// const BASE_PATH = 'https://assets.cbwc.io/nft/';
const BASE_PATH = "https://cbwc-static-nft-images.s3.amazonaws.com/cbwc-nfts/";

const CdnImageNft: FC<CdnImageNftProps> = ({
  id,
  className,
  defaultImage = defulatBear,
}) => {
  const [loading, setLoading] = useState(false);

  const onImageLoadError = (e: SyntheticEvent) => {
    (e.target as HTMLImageElement).onerror = () => {};
    (e.target as HTMLImageElement).src = defaultImage;
  };

  const onImageLoaded = () => {
    setLoading(true);
  };

  return (
    <>
      {id && (
        <img
          key={"CdnImageNft-" + id}
          className={className}
          src={BASE_PATH + id + ".jpg"}
          onError={onImageLoadError}
          onLoad={onImageLoaded}
          alt={"nft " + id}
        />
      )}
    </>
  );
};

export default CdnImageNft;
