import Swal from "sweetalert2";

// import { setRecoil } from "recoil-nexus";

import { getErrorMessage } from "../utils/walletConnectFunctions";
import { CHAIN_ID } from "../constants/constants";
import { approve } from "../contracts/functions/nftContract";
import ForgeWatchService from "../services/forge/forgeWatchService";
import ClaimPiecesService from "../services/forge/claimPiecesService";

export const walletConnectedMessageError = (e: any) => {
  if (e)
    Swal.fire({
      icon: "error",
      title: "Aw, Snap!",
      text: getErrorMessage(e)
    });
};

export const walletConnectedZerobalance = () => {
  console.log("firing error Zero NFT and Zero Arkoda Tokens ");
  Swal.fire({
    icon: "error",
    title: "You dont have NFTs",
    text: "please check account you are connecting with, you need Arkouda NFTs to connect",
    allowOutsideClick: false,
    didClose: () => {
      // setRecoil(closeSWALAtom, true);
    }
  });
};

export const transactionMessage = (e: any) => {
  if (e)
    Swal.fire({
      icon: "error",
      title: "Aw, Snap!",
      text: getErrorMessage(e)
    });
};

export const mintSuccessful = () => {
  Swal.fire({
    icon: "success",
    title: "Congratulations!",
    text: "Mint is Successful"
  });
};

export const mintFailed = () => {
  Swal.fire({
    icon: "error",
    title: "Aw, Snap!",
    text: "Your Transaction Has Failed!"
  });
};

export const transactionRejected = () => {
  Swal.fire("Aw, Snap!", "Transaction Rejected", "error");
};

export const transactionFailed = () => {
  Swal.fire({
    icon: "error",
    title: "Aw, Snap!",
    text: "Your Transaction Has Failed!"
  });
};

export const transactionSuccess = ({ msg = "Transaction is Successful" }) => {
  Swal.fire({
    icon: "success",
    title: "Congratulations!",
    text: msg
  });
};

export const transactionInProcess = ({ msg = "Transaction is in process" }) => {
  Swal.fire({
    icon: "success",
    title: "Transaction is submited!",
    text: msg
  });
};

export const switchMessage = (chainId: number) => {
  if (CHAIN_ID !== chainId) {
    Swal.fire({
      icon: "error",
      title: "Unsupported Network",
      text: `Please switch to Ethereum mainnet.`
    });
    return true;
  }
  return false;
};

export const notEligibleVIP = () => {
  Swal.fire({
    icon: "error",
    title: "Sorry you missed the Whitelist.",
    html: `Come back for the Public Sale on 22nd Feb at 11:30 PST/ 19:30 GMT<br>
    See yah then!`
  });
};

export const alreadyMinted = () => {
  Swal.fire({
    icon: "error",
    title: "Already minted upto allowed count",
    text: `You can now mint in public sale which is on 22nd February`
  });
};

export const approveDialog = (account: string) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You are required to give permission to stake your NFT!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, Approve it!"
  }).then((result) => {
    if (result.isConfirmed) {
      approve(account, (e) => {
        if (!e) {
          Swal.fire("Approved!", "Now you can stake your NFT.", "success");
        }
      });
    }
  });
};

//modal to forge
export const forgeDialogue = (options, callback) => {
  Swal.fire({
    title: "Forging Watch",
    text: "The pieces will be consumed in the process.",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Accept"
  }).then(async (result) => {
    if (result.isConfirmed) {
      const success = await ForgeWatchService(options);
      if (success) {
        callback();
        Swal.fire({
          title: "Forging Watch",
          text: "Congratulations your forge is succefully done",
          icon: "success",
          showCloseButton: true
        });
      } else {
        Swal.fire({
          title: "Forging Watch",
          text: "There was a problem forging.",
          icon: "error",
          showCloseButton: true
        });
      }
    }
  });
};

export const forgeTxSubmitted = async (hash) => {
  Swal.fire({
    html:
    '	<div className="modal fade" id="claimPieces" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">' +
    '<div className="modal-dialog modal-dialog-centered">' +
    '<div className="modal-content text-center">' +
    '<div className="modal-header centerModal">' +
    ' <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>' +
    '<h2 className="modal-title">FORGING/S<br /><span>IN PROGRESS</span></h2>' +
    "</div>" +
    '<div className="modal-body mt-3">' +
    `<p>forging watch is now in progress with HASH #${hash}. You will receive a notification once completed.</p>` +
    "</div>" +
    "</div>" +
    "</div>" +
    "</div>",
    showCancelButton: false,
    showCloseButton: false,
    showConfirmButton: false,
    showDenyButton: false
  });
}

export const claimPiecesDialogue = async (tokenIds, account, callback) => {
  Swal.fire({
    title: "Claiming",
    text: "Working on it...",
    showCancelButton: false,
    showCloseButton: false,
    showConfirmButton: false,
    showDenyButton: false,
  });
  const success = await ClaimPiecesService(tokenIds, account);
  if (success) {
    Swal.fire({
      html:
        '	<div className="modal fade" id="claimPieces" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">' +
        '<div className="modal-dialog modal-dialog-centered">' +
        '<div className="modal-content text-center">' +
        '<div className="modal-header centerModal">' +
        ' <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>' +
        '<h2 className="modal-title">WATCH PIECE/S<br /><span>CLAIMED SUCCESSFULLY</span></h2>' +
        "</div>" +
        '<div className="modal-body mt-3">' +
        "<p>You have successfully redeemed your watch pieces. Come back again next month for more airdropped watch pieces</p>" +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>",
      showCloseButton: true,
    });
    callback();
  } else {
    Swal.fire({
      title: "Error",
      text: "There was a problem claiming.",
      icon: "error",
      showCloseButton: true,
    });
  }
};
