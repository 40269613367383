/**
 * Actions Types
 */
export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';
export const WALLET_DETAILS_RESPONSE = 'WALLET_DETAILS_RESPONSE';
export const PEICES_RESPONSE = 'PEICES_RESPONSE';
export const OFFER_RESPONSE = 'OFFER_RESPONSE';
export const SIZE_SELECTED = 'SIZE_SELECTED';
export const CART_ITEMS = 'CART_ITEMS';




/**
 * @func addNotification
 * @param ``
 */
export const addNotification = (payload: any) => ({
  type: ADD_NOTIFICATIONS,
  payload,
});


 export const walletDetailResponse = (payload: any) => ({
  type: WALLET_DETAILS_RESPONSE,
  payload,
});


 export const piecesResponse = (payload: any) => ({
  type: PEICES_RESPONSE,
  payload,
});

export const offerResponse = (payload: any) => ({
  type: OFFER_RESPONSE,
  payload,
});


export const selectedItemSize = (payload: any) => ({
  type: SIZE_SELECTED,
  payload,
});

export const cartItemsUpdate = (payload: any) => ({
  type: CART_ITEMS,
  payload,
});
