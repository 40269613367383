export default function WatchComponent(props) {
  function handleStatus(uid) {
    props.handleStatus(uid);
  }

  const uid = props.watch.uid;
  const imageWatch =
    "https://cryptobearwatchclub.mypinata.cloud/ipfs/QmeQHGcTpUoTnJbAcBPNXfUuNAizVfq7vsDweWYwaaQadv/" +
    uid +
    ".jpeg";

  return (
    <div>
      <div className="contentImageWatch">
        <img
          className="imageWatch "
          alt={`watch-${props.watch.tokenId}`}
          src={imageWatch}
        />
      </div>
      <div className="pieceComponent">
        <div className="row">
          <div className="col-12">
            <h3 className="watchName">{props.watch.name}</h3>
          </div>
        </div>

        <div
          onClick={() => handleStatus(props.watch.uid)}
          className="w-100 btnSecondary"
        >
          VIEW DETAILS
        </div>
      </div>
    </div>
  );
}
