export const ROOT = `/`;

export const TOKEN_MONICS = `/tokenmonics`;
export const NEWS_BLOGS = `/newsblogs`;
export const NEWS_DETAILS = `/newsdetails`;
export const CONNECT = `/connect`;


export const MEMBER = `/member-`;
export const MEMBER_DASHBOARD = `${MEMBER}dashboard`;
export const MEMBER_STAKING = `${MEMBER}staking`;
export const MEMBER_OFFER = `${MEMBER}offer`;
export const MEMBER_SHOP = `${MEMBER}shop`;

//page forge watch
export const MEMBER_FORGE = `${MEMBER}forge`;
export const MEMBER_OFFER_DETAIL = `${MEMBER_OFFER}-detail`;


export const MEMBER_SHOP_LANDING = `${MEMBER_SHOP}-landing`;
export const MEMBER_SHOP_DETAILS = `${MEMBER_SHOP}-details/:id`;
export const MEMBER_SHOP_CART = `${MEMBER_SHOP}-cart`;
export const MEMBER_SHOP_SHIPPING = `${MEMBER_SHOP}-ship`;
