// @ts-nocheck
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as RoutePath from "../../../../router/config";
import { SIDEBAR_MEMBER_PORTAL_OPTIONS } from "../../../../utils/constants";
import logoCb from "../../../../assets/images/cbwc-logo.png";
import logoOpensea from "../../../../assets/images/OpenSea.svg";
import twitter from "../../../../assets/images/twitter.svg";
import discordimg from "../../../../assets/images/discord.svg";
import instaImg from "../../../../assets/images/insta.svg";
import { OPENSEA_LINK } from "../../../../constants/constants";
import { useCustomWeb3React } from "../../../../hooks/useCustomWeb3React";
import PDF from "../../../../assets/pdf/cbwc-whitepaper.pdf";

enum MAIN_MENU_TYPE {
  "HOME" = "HOME",
  "MEMBER" = "MEMBER",
  "NEWS" = "NEWS",
}

const NavbarSection = () => {
  const [onNavClick, setOnNavClick] = useState(false);
  const [selectedMenuType, setSelectedMenuType] = useState("");
  console.log("selectedMenuType: ", selectedMenuType);
  const [width, setWidth] = useState<number>(window.innerWidth);
  let navigate = useNavigate();

  const { account } = useCustomWeb3React();
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const onLinkClick = (e: any) => {
    setOnNavClick(!onNavClick);
  };

  const classnav = onNavClick
    ? "navbar-collapse offcanvas-collapse open"
    : "navbar-collapse offcanvas-collapse";

  const onMemberClick = () => {
    console.log("account: ", account);

    // connected ?
    if (account) {
      onMenuClick(MAIN_MENU_TYPE.MEMBER);
    } else {
      navigate(RoutePath.CONNECT);
    }
  };

  const onMenuClick = (type: string) => {
    if (isMobile) {
      if (type === selectedMenuType) {
        setSelectedMenuType("");
      } else {
        setSelectedMenuType(type);
      }
    }
  };

  const onTokenMonicsNavClick = () => {
    navigate(RoutePath.TOKEN_MONICS);
  };

  const onNewsClick = () => {
    navigate(RoutePath.NEWS_BLOGS);
  };

  return (
    <nav className="navbar navbar-expand-lg" aria-label="Main navigation">
      <div className="container">
        <a className="navbar-brand" href="index.html">
          <img
            className="light-mode-item navbar-brand-item"
            src={logoCb}
            alt="CBWC"
          />
        </a>

        <div className={classnav} id="navbarsExampleDefault">
          <div className="text-end socialIcons justify-content-end order-lg-last">
            <a
              href="https://opensea.io/collection/thecryptobearwatchclub"
              rel="noreferrer"
              target="_blank"
              title="BUY ON OPENSEA"
            >
              <img src={logoOpensea} alt="OpenSea" />
            </a>
            <a
              href="https://discord.gg/EuT4mnkAbM"
              rel="noreferrer"
              target="_blank"
              title="JOIN DISCORD"
            >
              <img src={discordimg} alt="Discord" />
            </a>
            <a
              href="https://twitter.com/ArkoudaNFT"
              rel="noreferrer"
              target="_blank"
              title="FOLLOW ON TWITTER"
            >
              <img src={twitter} alt="Twitter" />
            </a>
            <a
              href="https://www.instagram.com/arkouda_nft/"
              rel="noreferrer"
              target="_blank"
              title="FOLLOW ON INSTA"
            >
              <img src={instaImg} alt="Instagram" />
            </a>
          </div>
          <ul className="navbar-nav navbar-nav-scroll me-auto ms-lg-5">
            <li className={"nav-item dropdown"}>
              <div
                className="nav-link active dropdown-toggle"
                id="homeDropdown"
                role="button"
                onClick={() => onMenuClick(MAIN_MENU_TYPE.HOME)}
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                HOME
              </div>
              <ul
                className={
                  selectedMenuType === MAIN_MENU_TYPE.HOME
                    ? "dropdown-menu show-dropdown-menu"
                    : "dropdown-menu"
                }
                aria-labelledby="homeDropdown"
              >
                <li>
                  <a
                    className="dropdown-item"
                    href="#1stDropSection"
                    onClick={onLinkClick}
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    1ST DROP
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#newDropSection"
                    onClick={onLinkClick}
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    NEW DROP
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={onLinkClick}
                    href="#specialNFTSection"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    SPECIAL NFT
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={onLinkClick}
                    href="#perksSection"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    PERKS
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={onLinkClick}
                    href="#dashboardSection"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    DASHBOARD
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={onLinkClick}
                    href="#roadmapSection"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    ROADMAP
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={onLinkClick}
                    href="#aboutSection"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    ABOUT
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={onLinkClick}
                    href="#faqSection"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    FAQs
                  </a>
                </li>
              </ul>
            </li>

            {account && account !== undefined ? (
              <li className="nav-item dropdown">
                <div
                  className="nav-link dropdown-toggle"
                  id="memberDropdown"
                  role="button"
                  onClick={onMemberClick}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  MEMBER LOGIN
                </div>
                <ul
                  className={
                    selectedMenuType === MAIN_MENU_TYPE.MEMBER
                      ? "dropdown-menu show-dropdown-menu"
                      : "dropdown-menu"
                  }
                  aria-labelledby="memberDropdown"
                >
                  {SIDEBAR_MEMBER_PORTAL_OPTIONS.map((eachItem) => {
                    return (
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => navigate(eachItem.route)}
                        >
                          {eachItem.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </li>
            ) : (
              <li className="nav-item dropdown">
                <div
                  className="nav-link"
                  id="memberDropdown"
                  role="button"
                  onClick={onMemberClick}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  MEMBER LOGIN
                </div>
              </li>
            )}

            {/* <li style={{ cursor: "pointer" }} className="nav-item">
              <a className="nav-link" onClick={onNewsClick}>
                NEWS AND BLOGS
              </a>

            </li> */}
            <li style={{ cursor: "pointer" }} className="nav-item">
              <a className="nav-link" onClick={onTokenMonicsNavClick}>
                TOKENOMICS
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                target="_blank"
                rel="noreferrer"
                href={PDF}
              >
                WHITEPAPER
              </a>
            </li>
            <div className="hidden-sm d-lg-none d-xl-none d-xxl-none openSeaBtn">
              <a href={OPENSEA_LINK} className="btnPrimary">
                <img src={logoOpensea} alt="OpenSea" />
                BUY ON OPENSEA
              </a>
              <div onClick={onMemberClick} className="btnSecondary">
                MEMBER LOGIN
              </div>
            </div>
          </ul>
        </div>

        <button
          className="navbar-toggler p-0 border-0"
          type="button"
          id="navbarSideCollapse"
          onClick={onLinkClick}
          aria-label="Toggle navigation"
          data-toggle="collapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
  );
};

export default NavbarSection;
