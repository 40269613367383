import CdnImageNft from '../../../../common/CdnImageNft';

interface NftCardProps {
  onUnstakeClick: any,
  onStakeClick: any,
  onCheckToggel: any,
  data: any,
  isChecked: boolean,
  isStackedCheck: boolean
}


const NftCard = (props: NftCardProps) => {
  const { data = {}, onCheckToggel = () => { }, onStakeClick, onUnstakeClick, isChecked, isStackedCheck = false } = props;
  const { id = '', tier = '' } = data;



  const onCheckChange = () => {
    onCheckToggel(id);
  };

  const onStackbtnClick = () => {
    console.log('isStaked: ', isStackedCheck);
    if (isStackedCheck) {
      onUnstakeClick(id);
    } else {
      onStakeClick(id);
    }
  };

  const ROI_REWARDS = {
    1: '900',
    2: '270',
    3: '90',
  };

  return (
    <div key={"nftcard-" + id} className="col-6 col-lg-3">
      <div className="secondaryBG">
        <div className="myNFTthumb">
          <CdnImageNft id={id} className="img-fluid" />
        </div>
        <div className="myNFTdetails">
          <div className="stakeID">
            <h3 className="d-inline float-start">Arkouda #{id}</h3>

            <div
              onClick={onCheckChange}
              className="w-auto d-inline selectStake float-end"
            >
              <input
                type="checkbox"
                id={id}
                checked={isChecked}
              />
              <label htmlFor="chk02"></label>
            </div>
            <div className="clearfix"></div>
          </div>
          <h5 className="text-capitalize whiteColor opacity-50 mb-3">
            Tier {tier}
          </h5>
          <div>
            <h4 className="float-start">MONTHLY ROI</h4>
            <h6 className="float-end text-end text-uppercase whiteColor opacity-50">
              {ROI_REWARDS[tier]} REWARDS
            </h6>
            <div className="clearfix"></div>
          </div>
          <div className="mt-3 mt-lg-4">
            <div
              className={
                isStackedCheck ? 'w-100 btnPrimary whiteBG' : 'w-100 btnPrimary'
              }
              onClick={onStackbtnClick}
            >
              {isStackedCheck ? 'UNSTAKE' : 'STAKE'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NftCard;
