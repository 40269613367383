import { useEffect, useState } from 'react';
import { CONTRACT_ARKODA_ADDRESS_PROD } from '../../../../constants/constants';
import { cbwcTotalStacked } from '../../../../contracts/functions/nftContract';
import bear001 from '../../../../assets/images/bear-001.png';
import bear002 from '../../../../assets/images/bear-013.png';
import bear003 from '../../../../assets/images/bear-003.png';
import bear004 from '../../../../assets/images/bear-014.png';

const FirstDropSection = () => {
  const [respStaking, setRespStaking]: any = useState();
  const [loadingStaking, setLoadingStaking] = useState(false);

  useEffect(() => {
    async function geStatsDetails() {
      try {
        setLoadingStaking(true);
        const mResp = await cbwcTotalStacked(CONTRACT_ARKODA_ADDRESS_PROD);
        console.log('mResp: ', mResp);
        setRespStaking(mResp);
        setLoadingStaking(false);
      } catch (error) {
        setLoadingStaking(false);
      }
    }
    geStatsDetails();
  }, []);

  return (
    <section id="1stDropSection" className="firststDropSection">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-5 text-end text-lg-start col-xxl-5 pe-lg-0 contentBox">
            <h1 data-aos="fade-right">
              ARKOUDA
              <br />
              <span>
                FIRST DROP<div className="textNew textSoldout">Sold Out!</div>
              </span>
            </h1>
            <p className="mt-5" data-aos="fade-up">
              Now you see them. Now you don't. The first drop of 3,010 Arkouda NFTs is sold out. This was a collection of 450+ unique traits programmatically assigned, giving each model a distinct identity. These NFTs are 3D ready and will be enabled across the metaverse and in our soon-to-be-launched game. Owners can also access Arkouda’s premium 6x6 Sandbox plot.            </p>
            {/* <p data-aos="fade-up">
              These NFTs are 3D ready and will be enabled across the metaverse
              and in our soon-to-be-launched game. Owners can also access
              Arkouda's premium 6x6 Sandbox plot.
            </p> */}

            <div className="hero-funfact my-4 my-lg-5" data-aos="fade-up">
              <div className="row">
                <div className="col-xl-4 col-6">
                  <div className="single-fun-fact">
                    <h6>BEARS Available</h6>
                    <h4>3,010</h4>
                  </div>
                </div>
                <div className="col-xl-4 col-6">
                  <div className="single-fun-fact">
                    <h6>STAKED BEARS</h6>
                    <h4>
                      {/* {loadingStaking && 'Loading...'}
                      {errorStaking && '2,500'}
                      {respStaking &&
                        respStaking.hasOwnProperty('total_volume') &&
                        respStaking?.totalStake} */}
                      {respStaking}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 highlighterBg">
            <div className="row g-3">
              <div className="col-6">
                <div
                  className="text-center pt-3 px-4 boxMaxHeight"
                  data-aos="fade-left"
                >
                  <img src={bear001} alt="Bear" className="img-fluid" />
                </div>
              </div>
              <div className="col-6">
                <div
                  className="text-center pt-3 px-4 boxMaxHeight"
                  data-aos="fade-left"
                >
                  <img src={bear002} alt="Bear" className="img-fluid" />
                </div>
              </div>
              <div className="col-6">
                <div
                  className="text-center pt-3 px-4 boxMaxHeight"
                  data-aos="fade-up"
                >
                  <img src={bear003} alt="Bear" className="img-fluid" />
                </div>
              </div>
              <div className="col-6">
                <div
                  className="text-center pt-3 px-4 boxMaxHeight"
                  data-aos="fade-up"
                >
                  <img src={bear004} alt="Bear" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FirstDropSection;
