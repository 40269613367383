import { useEffect, useState } from 'react';
import "./HeadSection.module.scss";
import pawImg from "../../../../assets/images/fi-sr-paw.svg";
import networkImg from "../../../../assets/images/fi-sr-chart-network.svg";
import OpenSeaImg from "../../../../assets/images/OpenSea.svg";
import heroImg from "../../../../assets/images/hero-image-001.png";
import { OPENSEA_LINK } from '../../../../constants/constants';

const axios = require('axios');

const HeadSection = () => {
  const [resp, setResp]: any = useState({});
  console.log('resp: ', resp);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const getStats = async () => {
    console.log("totalValuem getstats")
    setLoading(true);

    await axios
      .get(process.env.REACT_APP_CBWC_WEB_API_URL + '/nft/nftStats')
      .then(function (response: any) {
        const { data = {} } = response;
        const { resp, error, success } = data;
        console.log('data: ', data);

        if (success) {
          setResp(resp);
        }
      })
      .catch(function (error: any) {
        setError(error);
      })
      .then(function () {
        // always executed
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!resp.hasOwnProperty('total_volume')) {
      getStats();
    }
  }, []);

  const RenderValumeTraded = () => (
    <h4>
      {loading && 'Loading...'}
      {error && '$1,790,827.39'}
      {resp &&
        resp.hasOwnProperty('total_volume') &&
        parseFloat(resp?.total_volume).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })}
    </h4>
  );

  const RenderDiscordMemeber = () => {
    return (
      <h4>
        {loading && 'Loading...'}
        {error && '33949'}
        {resp && resp.hasOwnProperty('discordCount') && resp?.discordCount}
      </h4>
    );
  };

  return (
    <>
      <section className="section001">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xxl-5 pe-lg-0">
              <div className="hero-content text-lg-start">
                <h1 data-aos="fade-up">
                  {/* CRYPTO
                  <br />
                  BEAR
                  <br />
                  <span>Watch Club</span> */}
                  <span>ARKOUDA</span>
                </h1>
                <p data-aos="fade-right" className="d-none d-lg-block">
                  Arkouda was launched with a mission to unite luxury watch and NFT collectors in a community by facilitating more access to the watches they seek in a virtual world dedicated to them.
                </p>

                <div
                  className="hero-funfact mt-2 d-none d-lg-block"
                  data-aos="fade-up"
                >
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="single-fun-fact mt-2">
                        <img
                          src={pawImg}
                          alt="Bears Volume Traded"
                          className="imgShadow"
                        />
                        <h6>BEARS Volume Traded</h6>
                        <RenderValumeTraded />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="single-fun-fact mt-2">
                        <img
                          src={networkImg}
                          alt="Community Members"
                          className="imgShadow"
                        />
                        <h6>Discord MEMBERS</h6>
                        <RenderDiscordMemeber />
                      </div>
                    </div>
                    <div className="xsFixedAtFooter">
                      <a
                        href={OPENSEA_LINK}
                        target="_blank"
                        rel="noreferrer"
                        className="btnPrimary m-2 btninRow"
                      >
                        <img src={OpenSeaImg} alt="OpenSea" />
                        BUY ON OPENSEA
                      </a>
                      <a
                        href="#newDropSection"
                        className="btnSecondary m-2 btninRow"
                      >
                        DISCOVER NEW DROP!
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xxl-7" data-aos="zoom-in-up">
              <div className="hero-image">
                <div className="big-img">
                  <img
                    src={heroImg}
                    alt="Hero"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fadedBackground"></div>
      </section>
      <div className="d-block d-lg-none heroCopy">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="col-lg-6 col-xxl-7">
              <p>
                Arkouda was launched with a mission to unite luxury watch and NFT collectors in a community by facilitating more access to the watches they seek in a virtual world dedicated to them.
              </p>

              <div className="hero-funfact mt-2">
                <div className="row">
                  <div className="col-6">
                    <div className="single-fun-fact mt-2">
                      <img
                        src={pawImg}
                        alt="Bears Volume Traded"
                        className="imgShadow"
                      />
                      <h6>BEARS Volume Traded</h6>
                      <RenderValumeTraded />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="single-fun-fact mt-2">
                      <img
                        src={networkImg}
                        alt="Community Members"
                        className="imgShadow"
                      />
                      <h6>Discord MEMBERS</h6>
                      <RenderDiscordMemeber />
                    </div>
                  </div>
                  <div className="xsFixedAtFooter">
                    <a
                      href="#newDropSection"
                      className="btnSecondary m-2 btninRow"
                    >
                      DISCOVER NEW DROP!
                    </a>
                    <a
                      href={OPENSEA_LINK}
                      target="_blank"
                      rel="noreferrer"
                      className="btnPrimary m-2 btninRow"
                    >
                      <img src={OpenSeaImg} alt="OpenSea" />
                      BUY ON OPENSEA
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeadSection;
