import aboutUsBear from "../../../../assets/images/bear-006.png";
import { SLICK_SETTINGS_RESPONSIVE } from "../../../../utils/constants";
import Slider from "react-slick";

const Aboutus = () => {
  return (
    <section id="aboutSection">
      <div className="container">
        {/* <div className="row justify-content-md-center">
          <div className="col-12 text-end text-lg-center aboutCWBC">
            <h1 data-aos="fade-up">
              <span>ABOUT</span>
              <br />
              ARKOUDA
            </h1>
            <img src={aboutUsBear} alt="Bear" className="ArkoudaImg" />
          </div>
        </div> */}

        <div className="row justify-content-md-center">
          <div className="col-12 text-end text-lg-center aboutCWBC">
            <h1 data-aos="fade-up" className="aos-init aos-animate">
              <span>ABOUT</span>
              <br />
              ARKOUDA
            </h1>
            <img src={aboutUsBear} alt="Bear" className="CBWCImg" style={{marginTop:"-100px", marginLeft:"80px"}}/>
          </div>
        </div>
        <Slider
          className="row justify-content-md-center responsive mt-3 mt-lg-5 slick-initialized slick-slider slick-dotted landingSiteSlicker"
          {...SLICK_SETTINGS_RESPONSIVE}
        >
          <div className="col text-center boxCnt" data-aos="fade-right">
            <div className="boxCntCopy">
              <h3>
                What is
                <br />
                Arkouda?
              </h3>
              <p className="mt-3">
                The most exciting project in recent times, Arkouda is a
                collection of 3,010 NFT collectibles. Each piece of artwork is
                hand-drawn with 450+ unique traits programmatically assigned,
                giving each model a distinct identity and an added value to the
                community.
              </p>
            </div>
          </div>
          <div className="col text-center boxCnt" data-aos="fade-right">
            <div className="boxCntCopy">
              <h3>
                What can
                <br />
                Arkouda members expect?
              </h3>
              <p className="mt-3">
                All Arkouda NFTs will be enabled across the entire metaverse as
                well as in our soon-to-be-launched game. All NFT owners also
                have access to the Club's premium 6x6 Sandbox plot, right next
                to the central Atari hub.
              </p>
            </div>
          </div>
          <div className="col text-center boxCnt" data-aos="fade-left">
            <div className="boxCntCopy">
              <h3>How is Arkouda different from other NFT projects?</h3>
              <p className="mt-3">
                Utility is what sets Arkouda apart from other NFT projects. We
                reward our owners with exclusive Arkouda Tokens that can be
                redeemed for real watches, every month. During our mint, we gave
                away real high-end watches; something no other project has done
                yet.
              </p>
            </div>
          </div>
          <div className="col text-center boxCnt" data-aos="fade-left">
            <div className="boxCntCopy">
              <h3>What are the other benefits for Arkouda members?</h3>
              <p className="mt-3">
                Our NFT ownership also awards club membership, exclusive perks
                and preferential access to high-profile events that celebrate
                the spirited nature of the community. This only scratches the
                surface of what is shaping up to be a game-changer in the
                metaverse.
              </p>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Aboutus;
