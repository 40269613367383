import { useState } from 'react';

interface FaqSliderProps {
  title: string;
  description: any;
}

const FaqSlider = (props: FaqSliderProps) => {
  const { title, description } = props;

  const [showDescription, setShowDescription] = useState(false);

  const onAccordonClick = () => {
    setShowDescription(!showDescription);
  };

  const crossClass = showDescription
    ? 'accordion-button'
    : 'accordion-button collapsed';

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingOne">
        <button className={crossClass} type="button" onClick={onAccordonClick}>
          {title}
        </button>
      </h2>
      {showDescription && (
        <div id="collapseOne" className="accordion-collapse collapse show">
          <div className="accordion-body">{description}</div>
        </div>
      )}
    </div>
  );
};

export default FaqSlider;
