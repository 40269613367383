import { useState } from 'react';
import FooterMember from '../FooterMember/FooterMember';
import NavbarMember from '../NavbarMember/NavbarMember';
import Sidebar from '../Sidebar/Sidebar';

interface HeaderTextPropsType {
  title: string;
  subtitle: string;
}

const Layout = (props: any) => {
  const { children } = props;

  const [sideMenuBtn, setSideMenuBtn] = useState(false);

  const onMenuBtnClick = () => {
    setSideMenuBtn(!sideMenuBtn);
  };

  return (
    <div className="adminSection">
      <NavbarMember onMenuClick={onMenuBtnClick}/>
      {/* mb-5 */}
      <div className="adminBlock">
        <div className="container">
          <div className="row">
            <Sidebar showBtn={sideMenuBtn} />
            <div className="bodyContent">{children}</div>
          </div>
        </div>
      </div>

      <FooterMember />
    </div>
  );
};

export default Layout;
