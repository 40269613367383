import React, { useState, useEffect } from 'react';
import NotificationCard from '../NavbarMember/NotificationCard';
import { StateContext, DispatchContext } from "../../../../context/context";
import * as AppContextAction from "../../../../context/actions/appContextActions";

const notificationMock = [
  {
    id: '',
    title: '',
    subtitle: '',
  },
];

const NotificationsList = (props: any) => {
  const { notifications = [] }: any = React.useContext(StateContext);
  const appContextDispatch = React.useContext(DispatchContext);

  const onNotificationCLick = (id: string) => {
    const filtered = notifications.filter((x) => x.id !== id);
    appContextDispatch(AppContextAction.addNotification(filtered));
  };

  // useEffect(() => {
  //   if(notifications && notifications.length> 0){
  //   }
  // }, [notifications]);

  return (
    <div className="notificationAlertText">
      {notifications.length > 0 &&
        notifications.map((eachNotice) => {
          return (
            <NotificationCard
              id={eachNotice.id}
              title={eachNotice.title}
              onClose={(id) => onNotificationCLick(id)}
              subtitle={eachNotice.subtitle}
            />
          );
        })}

      {notifications.length === 0 && (
        <div className="mt-3 notificationNoCard">
          <h3>No Notifications</h3>
        </div>
      )}
    </div>
  );
};

export default NotificationsList;
