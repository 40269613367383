import roadq12021 from '../../../../assets/images/road-map/q1-2021.png';
import roadq22021 from '../../../../assets/images/road-map/q2-2021.png';
import roadq12022 from '../../../../assets/images/road-map/q1-2022.png';
import roadq22023 from '../../../../assets/images/road-map/q2-2023.png';
import roadq32023 from '../../../../assets/images/road-map/q3-2023.png';
import roadq42023 from '../../../../assets/images/road-map/q4-2023.png';
import Slider from 'react-slick';
import { ROADMAP_PANNEL_SLICK_SETTINGS } from '../../../../utils/constants';

const Roadmap = () => {
  return (
    <section id="roadmapSection">
      <div className="container">
        <h1 className="text-center mb-5" data-aos="fade-up">
          <span>ARKOUDA</span>
          <br />
          ROADMAP
        </h1>
        <div className="row">
          <div className="col-12 col-lg-8 offset-lg-2">
            <p className="text-center" data-aos="fade-left">
              Strap yourselves in for an epic journey. We've set up a few checkpoints for Arkouda Bears to gain immortality. Once we've reached our percentages, the project will move forward several notches.Strap yourselves in for an epic journey. We've set up a few checkpoints for Arkouda Bears to gain immortality. Once we've reached our percentages, the project will move forward several notches.
            </p>
          </div>
        </div>
        <Slider
          className="row justify-content-md-center roadmapPanelSlide pt-3 pt-lg-5  slick-initialized slick-slider slick-dotted landingSiteSlicker"
          {...ROADMAP_PANNEL_SLICK_SETTINGS}
        >
          <div
            className="col text-center roadMapPanelBox"
            data-aos="fade-right"
          >
            <div className="row">
              <div>
                <h3>GRAND OPENING</h3>
                <div className="roadMapPanelBoxCont">
                  <p>
                    The private Discord channel opens up; members gain full
                    access to the club as well as global watch influencers and
                    traders.
                  </p>
                </div>
              </div>
              <div className="roadMapImgBox">
                <div className="imgSection">
                  <img src={roadq12021} alt="Opening" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <div
            className="col text-center roadMapPanelBox"
            data-aos="fade-right"
          >
            <div className="row">
              <div className="order-lg-3">
                <h3>
                  REVEAL <span>&amp; STAKING</span>
                </h3>
                <div className="roadMapPanelBoxCont">
                  <p>
                    The 3010 project is revealed! You can finally see your new shiny Arkouda! Next, the staking feature and the Arkouda
                    Token will be rolled out.{' '}
                  </p>
                </div>
              </div>
              <div className="order-lg-2 roadMapImgBox">
                <div className="imgSection">
                  <img src={roadq22021} alt="Reavel" />
                </div>
              </div>
            </div>
          </div>
          <div
            className="col text-center roadMapPanelBox"
            data-aos="fade-right"
          >
            <div className="row">
              <div>
                <h3>MERCHANDISE</h3>
                <div className="roadMapPanelBoxCont">
                  <p>
                    Custom Arkouda merchandise will soon be available for
                    release. Members can also get exclusive merchandise inspired
                    by the NFTs they have minted.
                  </p>
                </div>
              </div>
              <div className="roadMapImgBox">
                <div className="imgSection">
                  <img src={roadq12022} alt="Merchandise" />
                </div>
              </div>
            </div>
          </div>
          <div
            className="col text-center roadMapPanelBox"
            data-aos="fade-right"
          >
            <div className="row">
              <div className="order-lg-3">
                <h3>
                  COMMUNITY <span>PUZZLE GAMES</span>
                </h3>
                <div className="roadMapPanelBoxCont">
                  <p>
                    What if you could freely mint random pieces of a watch every
                    month? Scores of interesting activities are lined up to keep
                    the community engaged.
                  </p>
                </div>
              </div>
              <div className="order-lg-2 roadMapImgBox">
                <div className="imgSection">
                  <img src={roadq22023} alt="Community" />
                </div>
              </div>
            </div>
          </div>
          <div
            className="col text-center roadMapPanelBox"
            data-aos="fade-right"
          >
            <div className="row">
              <div>
                <h3>SANDBOX DEVELOPMENT</h3>
                <div className="roadMapPanelBoxCont">
                  <p>
                    Plans are afoot to create a virtual social club where Arkouda Members can connect within our premium 6x6 Sandbox hub in
                    the metaverse.
                  </p>
                </div>
              </div>
              <div className="roadMapImgBox">
                <div className="imgSection">
                  <img src={roadq32023} alt="Development" />
                </div>
              </div>
            </div>
          </div>
          <div
            className="col text-center roadMapPanelBox"
            data-aos="fade-right"
          >
            <div className="row">
              <div className="order-lg-3">
                <h3>
                  LAUNCH OF THE <span>NEW ARKOUDA BRAND</span>
                </h3>
                <div className="roadMapPanelBoxCont">
                  <p>
                    Arkouda will be a standalone brand that airdrops free 3D
                    NFTs to members. New characters may appear once all the
                    bears get their caves.
                  </p>
                </div>
              </div>
              <div className="order-lg-2 roadMapImgBox">
                <div className="imgSection">
                  <img src={roadq42023} alt="Launch" />
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Roadmap;
